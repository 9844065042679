import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** Map of Feature switch and its value for the org. */
  FeatureSwitchOverride: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: { input: any; output: any };
  /** Custom scalar for string and array of strings */
  StringOrArray: { input: any; output: any };
};

/** Details for an API token object */
export type ApiTokenDetails = AuthResourceDetails & {
  __typename?: 'APITokenDetails';
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
  usageDetails: Array<Maybe<AuthenticatorUsageDetails>>;
};

/** AWS integration connection details */
export type AwsIntegration = Integration & {
  __typename?: 'AWSIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** An abstracted version of our traditional PermissionObject, we are reusing some key fields while joining/simplifying the effect and filter fields (abstracted) */
export type AbstractedPermission = {
  __typename?: 'AbstractedPermission';
  action: Scalars['StringOrArray']['output'];
  allowFilter: Array<Scalars['String']['output']>;
  denyFilter: Array<Scalars['String']['output']>;
  path?: Maybe<Array<Array<Scalars['String']['output']>>>;
};

/** Details for an access key object */
export type AccessKeyDetails = AuthResourceDetails & {
  __typename?: 'AccessKeyDetails';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
  usageDetails: Array<Maybe<AuthenticatorUsageDetails>>;
};

/** Status of the access key auth resource */
export enum AccessKeyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** The count of the number of service identities that fall under a specific integrationId */
export type AccountByService = {
  __typename?: 'AccountByService';
  _id: IntegrationId;
  count: Scalars['Int']['output'];
};

/** The count of the number of service identities that fall under a specific identity classification */
export type AccountByType = {
  __typename?: 'AccountByType';
  _id: GranularType;
  count: Scalars['Int']['output'];
};

/** Active Directory integration details */
export type ActiveDirectoryIntegration = Integration & {
  __typename?: 'ActiveDirectoryIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A count of activities by a service/identity filter */
export type ActivitiesCount = {
  __typename?: 'ActivitiesCount';
  count: Scalars['Int']['output'];
};

/** Activity logs for an identity */
export type Activity = {
  __typename?: 'Activity';
  _id: Scalars['ObjectID']['output'];
  actionType: Scalars['String']['output'];
  actorName?: Maybe<Scalars['String']['output']>;
  actorType: ActorType;
  authResources?: Maybe<Array<AuthResource>>;
  detail?: Maybe<ActivityDetail>;
  identities?: Maybe<Array<ServiceIdentity>>;
  org: Org;
  service: Service;
  timestamp: Scalars['DateTime']['output'];
};

export type ActivityDetail = {
  activityId: Scalars['String']['output'];
  integrationId: IntegrationId;
};

/** Natoma actor type */
export enum ActorType {
  AccessToken = 'ACCESS_TOKEN',
  Anonymous = 'ANONYMOUS',
  ApiKey = 'API_KEY',
  External = 'EXTERNAL',
  System = 'SYSTEM',
  Uncategorized = 'UNCATEGORIZED',
  User = 'USER'
}

export type AgentLogSyncParams = {
  __typename?: 'AgentLogSyncParams';
  endTime: Scalars['DateTime']['output'];
  operation: SyncOperationType;
  startTime: Scalars['DateTime']['output'];
};

export type AgentUpdateParams = {
  __typename?: 'AgentUpdateParams';
  operation: SyncOperationType;
  version: Scalars['String']['output'];
};

/** A customer-facing alert */
export type Alert = {
  __typename?: 'Alert';
  _id: Scalars['ObjectID']['output'];
  /** The assignee of the alert */
  assignee: User;
  authResource?: Maybe<AuthResource>;
  /** When the alert was detected */
  detectedOn?: Maybe<Scalars['DateTime']['output']>;
  identity?: Maybe<Identity>;
  /** The auto-incrementing issue ID */
  issueId?: Maybe<Scalars['Int']['output']>;
  itsmTicket?: Maybe<ItsmTicket>;
  org: Org;
  service?: Maybe<Service>;
  serviceIdentity?: Maybe<ServiceIdentity>;
  severity: AlertSeverity;
  status: AlertStatus;
  target: AlertTarget;
  /** The type of alert */
  type: AlertName;
};

/** The count of the number of alerts that fall under one group by condition */
export type AlertCount = {
  __typename?: 'AlertCount';
  count: Scalars['Int']['output'];
  group: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
};

/** A list of groups of alerts */
export type AlertGroup = {
  __typename?: 'AlertGroup';
  alerts: Array<Alert>;
  assigneeObjects?: Maybe<Array<User>>;
  assignees?: Maybe<Array<Scalars['ObjectID']['output']>>;
  groupBy: AlertGroupBy;
  groupValue: AlertGroupByValue;
  identity?: Maybe<Identity>;
  integrationIds?: Maybe<Array<IntegrationId>>;
  severities?: Maybe<Array<AlertSeverityCount>>;
  total: Scalars['Int']['output'];
};

/** Alert field(s) to group by */
export enum AlertGroupBy {
  Assignee = 'ASSIGNEE',
  Identity = 'IDENTITY',
  IntegrationId = 'INTEGRATION_ID',
  Name = 'NAME',
  Severity = 'SEVERITY'
}

/** The value of the group in an alert grouping */
export type AlertGroupByValue = {
  __typename?: 'AlertGroupByValue';
  identity?: Maybe<Scalars['ObjectID']['output']>;
  integrationId?: Maybe<IntegrationId>;
  name?: Maybe<AlertName>;
};

/** The name of the alert */
export enum AlertName {
  AccountCapableOfNonHumanBehavior = 'ACCOUNT_CAPABLE_OF_NON_HUMAN_BEHAVIOR',
  AccountDormant = 'ACCOUNT_DORMANT',
  AccountPasswordDueForRotation = 'ACCOUNT_PASSWORD_DUE_FOR_ROTATION',
  AccountWithActivityRemoved = 'ACCOUNT_WITH_ACTIVITY_REMOVED',
  AuthenticatorDormant = 'AUTHENTICATOR_DORMANT',
  AuthenticatorDormantAndDueForRotation = 'AUTHENTICATOR_DORMANT_AND_DUE_FOR_ROTATION',
  AuthenticatorDueForRotation = 'AUTHENTICATOR_DUE_FOR_ROTATION',
  InteractiveActivityDetected = 'INTERACTIVE_ACTIVITY_DETECTED',
  NewAuthenticatorDiscovered = 'NEW_AUTHENTICATOR_DISCOVERED',
  OwnerRequired = 'OWNER_REQUIRED',
  RoleDormant = 'ROLE_DORMANT',
  RoleExternalUsageDetected = 'ROLE_EXTERNAL_USAGE_DETECTED'
}

/** The severity of the alert */
export enum AlertSeverity {
  High = 'HIGH',
  Info = 'INFO',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** The severity and corresponding count */
export type AlertSeverityCount = {
  __typename?: 'AlertSeverityCount';
  count: Scalars['Int']['output'];
  severity: AlertSeverity;
};

/** The status of an alert */
export enum AlertStatus {
  Active = 'ACTIVE',
  Dismissed = 'DISMISSED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Snoozed = 'SNOOZED',
  Tombstoned = 'TOMBSTONED'
}

/** Target entity of the alert */
export type AlertTarget = {
  __typename?: 'AlertTarget';
  ref?: Maybe<Scalars['ObjectID']['output']>;
  type?: Maybe<AlertTargetType>;
};

/** The type of an alert target */
export enum AlertTargetType {
  Authenticator = 'AUTHENTICATOR',
  Entitlement = 'ENTITLEMENT',
  Identity = 'IDENTITY',
  Role = 'ROLE',
  ServiceIdentity = 'SERVICE_IDENTITY'
}

/** Input body for updating an Alert */
export type AlertUpdateBody = {
  _id: Scalars['ObjectID']['input'];
  patch: AlertUpdatePatch;
};

/** Patch object for updating an alert */
export type AlertUpdatePatch = {
  assignee?: InputMaybe<Scalars['ObjectID']['input']>;
  assigneeType?: InputMaybe<IdentityOwnerType>;
  snoozeForDays?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AlertStatus>;
};

/** Input body for updating multiple Alerts */
export type AlertsUpdateBody = {
  /** The ID of the alert */
  _ids: Array<Scalars['ObjectID']['input']>;
  /** a single patch object to apply to all alerts */
  patch: AlertUpdatePatch;
};

/** Details for app password */
export type AppPasswordDetails = AuthResourceDetails & {
  __typename?: 'AppPasswordDetails';
  type: AuthResourceType;
};

export enum AuditActorType {
  User = 'USER'
}

export enum AuditEvent {
  AlertAccepted = 'ALERT_ACCEPTED',
  AlertCreated = 'ALERT_CREATED',
  AlertDismissed = 'ALERT_DISMISSED',
  AlertResolved = 'ALERT_RESOLVED',
  AlertSnoozed = 'ALERT_SNOOZED',
  IdentityClassificationChanged = 'IDENTITY_CLASSIFICATION_CHANGED',
  IdentityOwnerChanged = 'IDENTITY_OWNER_CHANGED',
  IntegrationAdded = 'INTEGRATION_ADDED',
  IntegrationRemoved = 'INTEGRATION_REMOVED',
  IntegrationUpdated = 'INTEGRATION_UPDATED',
  SsoConfigured = 'SSO_CONFIGURED',
  SsoRemoved = 'SSO_REMOVED',
  TicketCreated = 'TICKET_CREATED',
  UserCreatedViaSamlJitAccess = 'USER_CREATED_VIA_SAML_JIT_ACCESS',
  UserInvited = 'USER_INVITED',
  UserRoleChanged = 'USER_ROLE_CHANGED',
  UserSuspended = 'USER_SUSPENDED'
}

export type AuditLog = {
  __typename?: 'AuditLog';
  _id: Scalars['ObjectID']['output'];
  actor: AuditLogActor;
  event: AuditEvent;
  org: Org;
  targets?: Maybe<Array<AuditLogTarget>>;
  timestamp: Scalars['DateTime']['output'];
};

export type AuditLogActor = {
  details?: Maybe<User>;
  type: AuditActorType;
};

export type AuditLogActorDetails = AuditLogActor & {
  __typename?: 'AuditLogActorDetails';
  details?: Maybe<User>;
  type: AuditActorType;
};

/** Different fields to sort audit logs by */
export enum AuditLogSort {
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC'
}

export type AuditLogTarget = {
  details?: Maybe<AuditTargetResource>;
  ref?: Maybe<Scalars['ObjectID']['output']>;
  type: AuditTargetType;
};

export type AuditLogTargetDetails = AuditLogTarget & {
  __typename?: 'AuditLogTargetDetails';
  details?: Maybe<AuditTargetResource>;
  ref?: Maybe<Scalars['ObjectID']['output']>;
  type: AuditTargetType;
};

export type AuditTargetResource = Alert | User;

export enum AuditTargetType {
  Alert = 'ALERT',
  User = 'USER'
}

/** An auth resource provided or managed by a third party service, and owned or assigned to one or more service identities */
export type AuthResource = {
  __typename?: 'AuthResource';
  _id: Scalars['ObjectID']['output'];
  createdOn?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<AuthResourceDetails>;
  externalId: Scalars['String']['output'];
  integrationId: IntegrationId;
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  org: Org;
  service: Service;
  /** All service identities associated with this resource */
  serviceIdentities: Array<ServiceIdentity>;
  status: AuthResourceStatus;
};

/** An auth resource provided or managed by a third party service, and owned or assigned to one or more service identities */
export type AuthResourceServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AuthResourceDetails = {
  type: AuthResourceType;
};

/** The status of a resource associated with a service */
export enum AuthResourceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Tombstoned = 'TOMBSTONED'
}

/** The kind of resource represented */
export enum AuthResourceType {
  AccessKey = 'ACCESS_KEY',
  ApiToken = 'API_TOKEN',
  AppPassword = 'APP_PASSWORD',
  Certificate = 'CERTIFICATE',
  ClientSecret = 'CLIENT_SECRET',
  Group = 'GROUP',
  KeyPair = 'KEY_PAIR',
  Oauth = 'OAUTH',
  PermissionSet = 'PERMISSION_SET',
  Role = 'ROLE',
  RoleAssignment = 'ROLE_ASSIGNMENT'
}

/** The count of the number of authenticators that fall under a specific type */
export type AuthenticatorByType = {
  __typename?: 'AuthenticatorByType';
  _id: AuthResourceType;
  count: Scalars['Int']['output'];
};

/** Details for an authenticator object */
export type AuthenticatorDetails = AuthResourceDetails & {
  __typename?: 'AuthenticatorDetails';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
  usageDetails: Array<Maybe<AuthenticatorUsageDetails>>;
};

/** Details of an authenticator usage */
export type AuthenticatorUsageDetails = {
  __typename?: 'AuthenticatorUsageDetails';
  integrationId?: Maybe<InferredIntegrationId>;
  lastUsed: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  service?: Maybe<Scalars['ObjectID']['output']>;
};

/** Auxiliary Service metadata if the ServiceType is AUXILIARY */
export type Auxiliary = {
  __typename?: 'Auxiliary';
  integrationId?: Maybe<IntegrationId>;
};

/** Input body for updating multiple Identity objects. */
export type BulkUpdatesIdentitiesInput = {
  _ids: Array<InputMaybe<Scalars['ObjectID']['input']>>;
  patch: IdentityUpdatePatch;
};

export type ChangeUserRoleInput = {
  /** The new role to assign to the user. */
  newRole: UserRole;
  userId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** A classification for an identity */
export type Classification = {
  __typename?: 'Classification';
  accountType?: Maybe<IdentityAccountType>;
  actor?: Maybe<User>;
  decidedAt: Scalars['DateTime']['output'];
  scope?: Maybe<IdentityScope>;
};

/** Fields for overriding classifications */
export type ClassificationOverride = {
  overridesFile: Scalars['String']['input'];
};

/** Returns if the override was successful or not */
export type ClassificationOverrideSuccess = {
  __typename?: 'ClassificationOverrideSuccess';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Rule used to classify identities as human or non-human */
export type ClassificationRule = {
  __typename?: 'ClassificationRule';
  _id: Scalars['ObjectID']['output'];
  category: MatchType;
  description?: Maybe<Scalars['String']['output']>;
  property: Scalars['String']['output'];
  rule: Scalars['String']['output'];
  scope: IdentityScope;
  targetType: ClassificationRuleTargetType;
  type: ClassificationRuleType;
};

/** Fields for the create body */
export type ClassificationRuleCreateBody = {
  /** The kind of identity this classification rule matches */
  category: MatchType;
  /** Optional user-provided description for this rule, for context or summary */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The property of a normalized identity that this classification rule matches against */
  property: Scalars['String']['input'];
  /** The value for this rule, interpreted based on the type */
  rule: Scalars['String']['input'];
  /** The scope of the identity this classification rule matches */
  scope: IdentityScope;
  /** The target type of this classification rule */
  targetType: ClassificationRuleTargetType;
  /** The kind of match this is, basic classification rules are either exact string matches, substring matches, or regex matches */
  type: ClassificationRuleType;
};

/** The types of matched identities */
export enum ClassificationRuleTargetType {
  Group = 'GROUP',
  Identity = 'IDENTITY',
  Resource = 'RESOURCE'
}

/** Rule types for classification rules, used for categorizing identities */
export enum ClassificationRuleType {
  ExactMatch = 'EXACT_MATCH',
  Regex = 'REGEX',
  SubstringMatch = 'SUBSTRING_MATCH',
  TrieSearch = 'TRIE_SEARCH'
}

/** Rule used to classify identities as human or non-human */
export type ClassificationRuleset = {
  __typename?: 'ClassificationRuleset';
  _id: Scalars['ObjectID']['output'];
  createdAt: Scalars['DateTime']['output'];
  org: Org;
  rules: Array<Maybe<ClassificationRule>>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Fields to create a new ruleset body */
export type ClassificationRulesetCreateBody = {
  /** A list of classification rules to apply ordered by priority */
  rules?: InputMaybe<Array<InputMaybe<ClassificationRuleCreateBody>>>;
};

export type CreateItsmTicketDetails = {
  assigneeEmail?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  labels: Array<Scalars['String']['input']>;
  projectKey: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateItsmTicketInput = {
  alertId: Scalars['ObjectID']['input'];
  details: CreateItsmTicketDetails;
};

/** Required information to create an ingest job */
export type CreateIngestJobInput = {
  context?: InputMaybe<Scalars['ObjectID']['input']>;
  contextType?: InputMaybe<JobContextType>;
  dependsOn?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  type: JobType;
};

export type CreateIntegrationInput = {
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['input'];
  authorizedScopes: Array<Scalars['String']['input']>;
  instanceName?: InputMaybe<Scalars['String']['input']>;
  integrationId: IntegrationId;
};

/** Required information to create a job chain */
export type CreateJobChainInput = {
  context: Scalars['ObjectID']['input'];
  contextType: JobContextType;
  jobChainType: JobChainType;
  params?: InputMaybe<JobChainParams>;
};

/** Input for creating a new user to the current organization based on an existing Human Identity */
export type CreateUserFromIdentityInput = {
  /** The reference of the Human Identity based on which the User is to be created. */
  identityRef: Scalars['String']['input'];
};

/** Dashboard accounts by service and type */
export type DashboardAccountsByServiceAndType = {
  __typename?: 'DashboardAccountsByServiceAndType';
  /** A mapping of granular type to the number of accounts with that type */
  counts: Scalars['JSON']['output'];
  integrationId: IntegrationId;
};

/** Dashboard alerts by name and severity */
export type DashboardAlertsByNameAndSeverity = {
  __typename?: 'DashboardAlertsByNameAndSeverity';
  alertName: AlertName;
  /** A mapping of alert severity to the number of alerts with that severity */
  counts: Scalars['JSON']['output'];
  /** Total number of alerts matching the alertName. */
  total: Scalars['Int']['output'];
};

/** Dashboard alerts by name and severity */
export type DashboardAlertsByServiceAndSeverity = {
  __typename?: 'DashboardAlertsByServiceAndSeverity';
  /** An object containing each severity as a key, and the number of alerts with that severity as the value. */
  counts: Scalars['JSON']['output'];
  service: Service;
  /** Total number of alerts in the service. */
  total: Scalars['Int']['output'];
};

/** Dashboard alerts by severity */
export type DashboardAlertsBySeverity = {
  __typename?: 'DashboardAlertsBySeverity';
  /** Total number of alerts matching the alertSeverity. */
  count: Scalars['Int']['output'];
  severity: AlertSeverity;
};

/** Dashboard authenticators by service and type */
export type DashboardAuthenticatorsByServiceAndType = {
  __typename?: 'DashboardAuthenticatorsByServiceAndType';
  /** A mapping of granular type to the number of authenticators with that type */
  counts: Scalars['JSON']['output'];
  integrationId: IntegrationId;
};

/** A simple type to confirm the deletion of a particular object */
export type DeleteResult = {
  __typename?: 'DeleteResult';
  _id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** User data from descope (used for authenticating users). */
export type DescopeData = {
  __typename?: 'DescopeData';
  /** The user ID from Descope. */
  userId?: Maybe<Scalars['String']['output']>;
};

export type DirectorySyncParams = {
  __typename?: 'DirectorySyncParams';
  operation: SyncOperationType;
};

/** Entra integration details */
export type EntraIntegration = Integration & {
  __typename?: 'EntraIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A general error object */
export type Error = {
  __typename?: 'Error';
  message: Scalars['String']['output'];
};

export type EventSyncParams = {
  __typename?: 'EventSyncParams';
  operation: SyncOperationType;
  startTime: Scalars['DateTime']['output'];
};

/** Details for a generic activity */
export type GenericActivityDetail = ActivityDetail & {
  __typename?: 'GenericActivityDetail';
  activityId: Scalars['String']['output'];
  integrationId: IntegrationId;
};

/** Different sorts for the activities query */
export enum GetActivitiesSort {
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC'
}

/** Fields to sort by for alerts */
export enum GetAlertsSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  SeverityAsc = 'SEVERITY_ASC',
  SeverityDesc = 'SEVERITY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Different fields to sort by for the authResources query */
export enum GetAuthResourcesSort {
  AgeAsc = 'AGE_ASC',
  AgeDesc = 'AGE_DESC',
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  LastActivityDesc = 'LAST_ACTIVITY_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

/** Different sorts for the identities query */
export enum GetIdentitiesSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Different sorts for the identity owner query */
export enum GetIdentityOwnersSort {
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC'
}

/** Different sorts for the orgs query */
export enum GetOrgsSort {
  DisplaynameAsc = 'DISPLAYNAME_ASC',
  DisplaynameDesc = 'DISPLAYNAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

/** Different fields to sort by for the service identities query */
export enum GetServiceIdentitiesSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  LastActivityDesc = 'LAST_ACTIVITY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Different sorts for the users query */
export enum GetUsersSort {
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC'
}

/** Github activity detail */
export type GithubActivityDetail = ActivityDetail & {
  __typename?: 'GithubActivityDetail';
  activityId: Scalars['String']['output'];
  actorCountryCode?: Maybe<Scalars['String']['output']>;
  githubActionTypes?: Maybe<Scalars['String']['output']>;
  githubTargetUser?: Maybe<Scalars['String']['output']>;
  githubTargetUserId?: Maybe<Scalars['Int']['output']>;
  githubUserId?: Maybe<Scalars['Int']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  org?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['Int']['output']>;
  permission?: Maybe<Scalars['String']['output']>;
  repo?: Maybe<Scalars['String']['output']>;
  repoPublic?: Maybe<Scalars['Boolean']['output']>;
  team?: Maybe<Scalars['String']['output']>;
};

/** GitHub integration connection details */
export type GithubIntegration = Integration & {
  __typename?: 'GithubIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  organization?: Maybe<Scalars['String']['output']>;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** Google workspace activity detail */
export type GoogleWorkspaceActivityDetail = ActivityDetail & {
  __typename?: 'GoogleWorkspaceActivityDetail';
  activityId: Scalars['String']['output'];
  actorIpAddress?: Maybe<Scalars['String']['output']>;
  applicationName?: Maybe<Scalars['String']['output']>;
  googleUserId?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
};

/** Google workspace connection details */
export type GoogleWorkspaceIntegration = Integration & {
  __typename?: 'GoogleWorkspaceIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  tokenType?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The kind of resource represented */
export enum GranularType {
  Application = 'APPLICATION',
  Machine = 'MACHINE',
  Role = 'ROLE',
  ServiceAccount = 'SERVICE_ACCOUNT',
  System = 'SYSTEM',
  Unknown = 'UNKNOWN',
  UserAccount = 'USER_ACCOUNT',
  UserAccountWithProgramaticCapability = 'USER_ACCOUNT_WITH_PROGRAMATIC_CAPABILITY'
}

/** Details for a group object */
export type GroupDetails = AuthResourceDetails & {
  __typename?: 'GroupDetails';
  authResources: Array<Maybe<Scalars['ObjectID']['output']>>;
  members: Array<Maybe<Scalars['ObjectID']['output']>>;
  type: AuthResourceType;
};

export type GroupSyncParams = {
  __typename?: 'GroupSyncParams';
  operation: SyncOperationType;
};

export type ItsmServiceUpdatePatchInput = {
  /** Project key for the ITSM service */
  projectKey?: InputMaybe<Scalars['String']['input']>;
};

/** ITSM ticket information */
export type ItsmTicket = {
  __typename?: 'ITSMTicket';
  createdAt: Scalars['DateTime']['output'];
  externalTicketId: Scalars['String']['output'];
  externalTicketURL: Scalars['String']['output'];
  integrationId: Scalars['String']['output'];
  lastSyncedAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

export type ItsmWorkspace = {
  __typename?: 'ITSMWorkspace';
  projectId: Scalars['String']['output'];
  projectKey: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
};

/** A non-human identity within Natoma, derived from individual service identities */
export type Identity = {
  __typename?: 'Identity';
  _id: Scalars['ObjectID']['output'];
  classification?: Maybe<Classification>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  org: Org;
  owner?: Maybe<User>;
  /** All service identities associated with this identity */
  serviceIdentities: Array<ServiceIdentity>;
  servicesCount?: Maybe<Scalars['Int']['output']>;
  status: IdentityStatus;
  userRef?: Maybe<User>;
};

/** A non-human identity within Natoma, derived from individual service identities */
export type IdentityServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The type of account this identity represents */
export enum IdentityAccountType {
  Human = 'HUMAN',
  NonHuman = 'NON_HUMAN',
  Uncategorized = 'UNCATEGORIZED',
  Unknown = 'UNKNOWN'
}

/** Input body for creating an Identity object. */
export type IdentityCreateBody = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
  serviceId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** identity or error object */
export type IdentityOrError = Error | Identity;

/** A Potential Identity Owner which could be a User or a Human Identity */
export type IdentityOwner = {
  __typename?: 'IdentityOwner';
  _id: Scalars['ObjectID']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  org: Org;
  ownerType?: Maybe<IdentityOwnerType>;
};

/** The type of Identity Owner */
export enum IdentityOwnerType {
  Identity = 'IDENTITY',
  User = 'USER'
}

/** The principal of a given service identity */
export type IdentityPrincipal = {
  __typename?: 'IdentityPrincipal';
  principal: Scalars['String']['output'];
  type: IdentityPrincipalType;
};

/** The type of principal by which this identity is represented, e.g. email, uid */
export enum IdentityPrincipalType {
  AppId = 'APP_ID',
  Email = 'EMAIL',
  PublicKey = 'PUBLIC_KEY',
  Uid = 'UID'
}

/** The scope of a identity */
export enum IdentityScope {
  InScope = 'IN_SCOPE',
  OutOfScope = 'OUT_OF_SCOPE'
}

/** The status of a non-human identity */
export enum IdentityStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE'
}

/** Input body for updating an Identity object. */
export type IdentityUpdateBody = {
  /** ID of the identity to update the type. */
  _id: Scalars['String']['input'];
  patch: IdentityUpdatePatch;
};

/** Patch object for updating an identity */
export type IdentityUpdatePatch = {
  accountType?: InputMaybe<IdentityAccountType>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
  ownerType?: InputMaybe<IdentityOwnerType>;
};

export type Inferred = {
  __typename?: 'Inferred';
  integrationId?: Maybe<InferredIntegrationId>;
  integrationLookupId?: Maybe<Scalars['String']['output']>;
};

/** Set of available integration IDs for inferred integrations */
export enum InferredIntegrationId {
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  Aws = 'AWS',
  Datadog = 'DATADOG',
  Entra = 'ENTRA',
  Github = 'GITHUB',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  Jira = 'JIRA',
  Okta = 'OKTA',
  Salesforce = 'SALESFORCE',
  ServiceNow = 'SERVICE_NOW',
  Slack = 'SLACK',
  Snowflake = 'SNOWFLAKE'
}

/** The processing metadata of an ingest job */
export type IngestJobProcessingMetadata = {
  __typename?: 'IngestJobProcessingMetadata';
  expectedIdentities?: Maybe<Scalars['Int']['output']>;
  processedIdentities?: Maybe<Scalars['Int']['output']>;
};

export type Integration = {
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** The available authentication types for an integration */
export enum IntegrationAuthTypes {
  ApiKey = 'API_KEY',
  AsyncConfiguration = 'ASYNC_CONFIGURATION',
  AsyncConfigurationBulk = 'ASYNC_CONFIGURATION_BULK',
  ClientCredentials = 'CLIENT_CREDENTIALS',
  Install = 'INSTALL',
  Oauth = 'OAUTH',
  ServiceAccount = 'SERVICE_ACCOUNT'
}

/** A sum of configured services for a given integrationId */
export type IntegrationCount = {
  __typename?: 'IntegrationCount';
  count: Scalars['Int']['output'];
  integrationId: IntegrationId;
};

/** Set of available integration IDs */
export enum IntegrationId {
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  Aws = 'AWS',
  Entra = 'ENTRA',
  Github = 'GITHUB',
  GoogleWorkspace = 'GOOGLE_WORKSPACE',
  Jira = 'JIRA',
  Okta = 'OKTA',
  Salesforce = 'SALESFORCE',
  ServiceNow = 'SERVICE_NOW',
  Slack = 'SLACK',
  Snowflake = 'SNOWFLAKE'
}

/** The status of an integration connection */
export enum IntegrationStatus {
  Active = 'ACTIVE',
  Disconnected = 'DISCONNECTED'
}

/** Input for inviting a user to the current organization. */
export type InviteUserInput = {
  /** The email address of the user to invite. */
  email: Scalars['String']['input'];
  /** The role of the user to invite. */
  role?: UserRole;
};

/** Jira integration details */
export type JiraIntegration = Integration & {
  __typename?: 'JiraIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** A record for a identity ingest job */
export type Job = {
  __typename?: 'Job';
  _id: Scalars['ObjectID']['output'];
  adminInitiated?: Maybe<Scalars['Boolean']['output']>;
  areDependenciesCompleted?: Maybe<Scalars['Boolean']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  context?: Maybe<JobContext>;
  dependsOn?: Maybe<Array<Maybe<Scalars['ObjectID']['output']>>>;
  error?: Maybe<Scalars['String']['output']>;
  missingScopes?: Maybe<Array<Scalars['String']['output']>>;
  org: Org;
  processingMetadata?: Maybe<IngestJobProcessingMetadata>;
  queuedAt: Scalars['DateTime']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  triggeredBy?: Maybe<User>;
  type: JobType;
};

/** A chain of jobs */
export type JobChain = {
  __typename?: 'JobChain';
  _id: Scalars['ObjectID']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  context?: Maybe<JobContext>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The error surfaced to the end user */
  error?: Maybe<Scalars['String']['output']>;
  missingScopes?: Maybe<Array<Scalars['String']['output']>>;
  org: Org;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: JobStatus;
  type: JobChainType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Parameters for a job chain */
export type JobChainParams = {
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The type of job chain */
export enum JobChainType {
  ActivityBackpatch = 'ACTIVITY_BACKPATCH',
  Daily = 'DAILY',
  Ingest = 'INGEST'
}

/** The context of an job */
export type JobContext = {
  __typename?: 'JobContext';
  ref?: Maybe<Scalars['ObjectID']['output']>;
  service?: Maybe<Service>;
  type?: Maybe<JobContextType>;
};

/** The type of document to which the context ref refers */
export enum JobContextType {
  Org = 'Org',
  Service = 'Service',
  ServiceIdentity = 'ServiceIdentity'
}

/** The status of a job */
export enum JobStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Queued = 'QUEUED'
}

/** The type of this job */
export enum JobType {
  Activity = 'ACTIVITY',
  AuthResource = 'AUTH_RESOURCE',
  Classification = 'CLASSIFICATION',
  Group = 'GROUP',
  Identity = 'IDENTITY',
  OrgLevel = 'ORG_LEVEL',
  PeriodicAlert = 'PERIODIC_ALERT',
  Service = 'SERVICE',
  TargetResource = 'TARGET_RESOURCE',
  UserDetail = 'USER_DETAIL'
}

/** The types of matched identities */
export enum MatchType {
  Human = 'HUMAN',
  NonHuman = 'NON_HUMAN'
}

export type MeResponse = {
  __typename?: 'MeResponse';
  /** The current logged in org. */
  currentOrg?: Maybe<Org>;
  /** The logged-in current user. */
  currentUser?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeUserRole: User;
  createClassificationRuleset: ClassificationRuleset;
  createITSMTicketFromAlert?: Maybe<ItsmTicket>;
  createIdentity: Identity;
  createIngestJob: Job;
  createJobChain: Array<Job>;
  createService: Service;
  createServiceFromIntegration: Service;
  createSyncOperation: SyncOperation;
  createUserFromIdentity: User;
  deleteServiceIntegration?: Maybe<DeleteResult>;
  deleteUser: User;
  inviteUser: User;
  overrideClassifications: ClassificationOverrideSuccess;
  register: MeResponse;
  updateAlert: Alert;
  updateAlerts?: Maybe<UpdateAlertsResult>;
  updateITSMService: Service;
  updateIdentities: Array<Identity>;
  updateIdentity: Identity;
  updateOrg: Org;
  updateOrgFeatureSwitch: Org;
  updateService: Service;
  updateServiceIdentity: ServiceIdentity;
};

export type MutationChangeUserRoleArgs = {
  input: ChangeUserRoleInput;
};

export type MutationCreateClassificationRulesetArgs = {
  body: ClassificationRulesetCreateBody;
};

export type MutationCreateItsmTicketFromAlertArgs = {
  input: CreateItsmTicketInput;
};

export type MutationCreateIdentityArgs = {
  input: IdentityCreateBody;
};

export type MutationCreateIngestJobArgs = {
  input: CreateIngestJobInput;
};

export type MutationCreateJobChainArgs = {
  input: CreateJobChainInput;
};

export type MutationCreateServiceArgs = {
  input: ServiceCreateBody;
};

export type MutationCreateServiceFromIntegrationArgs = {
  input: CreateIntegrationInput;
};

export type MutationCreateSyncOperationArgs = {
  input: SyncOperationCreateBody;
};

export type MutationCreateUserFromIdentityArgs = {
  input: CreateUserFromIdentityInput;
};

export type MutationDeleteServiceIntegrationArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type MutationDeleteUserArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationOverrideClassificationsArgs = {
  input: ClassificationOverride;
};

export type MutationRegisterArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  featureFlags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  orgDisplayName?: InputMaybe<Scalars['String']['input']>;
  orgSlug?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateAlertArgs = {
  input: AlertUpdateBody;
};

export type MutationUpdateAlertsArgs = {
  input: AlertsUpdateBody;
};

export type MutationUpdateItsmServiceArgs = {
  input: ItsmServiceUpdatePatchInput;
};

export type MutationUpdateIdentitiesArgs = {
  input: BulkUpdatesIdentitiesInput;
};

export type MutationUpdateIdentityArgs = {
  input: IdentityUpdateBody;
};

export type MutationUpdateOrgArgs = {
  input: OrgUpdateBody;
};

export type MutationUpdateOrgFeatureSwitchArgs = {
  input: OrgFeatureSwitchBody;
};

export type MutationUpdateServiceArgs = {
  input: ServiceUpdateBody;
};

export type MutationUpdateServiceIdentityArgs = {
  input: ServiceIdentityUpdateBody;
};

/** Details for an oauth object */
export type OAuthDetails = AuthResourceDetails & {
  __typename?: 'OAuthDetails';
  scopes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  serviceIdentityRef: Scalars['ObjectID']['output'];
  type: AuthResourceType;
  usageDetails: Array<Maybe<AuthenticatorUsageDetails>>;
};

/** Okta activity detail */
export type OktaActivityDetail = ActivityDetail & {
  __typename?: 'OktaActivityDetail';
  activityId: Scalars['String']['output'];
  actorIpAddress?: Maybe<Scalars['String']['output']>;
  displayMessage?: Maybe<Scalars['String']['output']>;
  eventType: Scalars['String']['output'];
  integrationId: IntegrationId;
  oktaTargets?: Maybe<Array<Maybe<OktaTarget>>>;
  oktaUserAltId?: Maybe<Scalars['String']['output']>;
  oktaUserDisplayName?: Maybe<Scalars['String']['output']>;
  oktaUserId?: Maybe<Scalars['String']['output']>;
  severity: Scalars['String']['output'];
};

/** Okta integration connection details */
export type OktaIntegration = Integration & {
  __typename?: 'OktaIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** Okta targets associated with the activity log */
export type OktaTarget = {
  __typename?: 'OktaTarget';
  alternateId?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** An object to metadata about an Organization’s onboarding process. */
export type Onboarding = {
  __typename?: 'Onboarding';
  connectedIDP: Scalars['Boolean']['output'];
};

export type OperationParams =
  | AgentLogSyncParams
  | AgentUpdateParams
  | DirectorySyncParams
  | EventSyncParams
  | GroupSyncParams;

/** A bag of timestamps representing state changes for this operation */
export type OperationTimestamps = {
  __typename?: 'OperationTimestamps';
  abandonedAt?: Maybe<Scalars['DateTime']['output']>;
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  pendingAt?: Maybe<Scalars['DateTime']['output']>;
  syncingAt?: Maybe<Scalars['DateTime']['output']>;
};

/** A singular tenant in Natoma. */
export type Org = {
  __typename?: 'Org';
  _id: Scalars['ObjectID']['output'];
  defaultITSMService?: Maybe<Service>;
  displayName?: Maybe<Scalars['String']['output']>;
  /** Feature switch overrides for the org */
  featureSwitchOverrides: Scalars['FeatureSwitchOverride']['output'];
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  /** Onboarding progress for an Organization. */
  onboarding?: Maybe<Onboarding>;
  slug: Scalars['String']['output'];
  status: OrgStatus;
  /** When the Org was tombstoned */
  tombstonedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrgFeatureSwitchBody = {
  featureSwitchOverrides: Scalars['FeatureSwitchOverride']['input'];
};

/** The status of an organization */
export enum OrgStatus {
  Active = 'ACTIVE',
  Tombstoned = 'TOMBSTONED'
}

/** Input body fo rupdating an Identity object. */
export type OrgUpdateBody = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<OrgStatus>;
};

/** A single permission attribute */
export type Permission = {
  __typename?: 'Permission';
  action: Scalars['StringOrArray']['output'];
  effect?: Maybe<PermissionEffect>;
  filter?: Maybe<Scalars['StringOrArray']['output']>;
  path?: Maybe<Array<Array<Scalars['String']['output']>>>;
};

/** A single permissions mapping details */
export type PermissionDetails = {
  __typename?: 'PermissionDetails';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentPermission?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<PermissionSeverity>;
  type?: Maybe<PermissionType>;
};

/** ALLOW or DENY a specific permission action */
export enum PermissionEffect {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

/** Details for a permission set object */
export type PermissionSetDetails = AuthResourceDetails & {
  __typename?: 'PermissionSetDetails';
  permissions?: Maybe<Array<Maybe<Permission>>>;
  type: AuthResourceType;
};

/** What is the risk assigned to this permission */
export enum PermissionSeverity {
  High = 'HIGH',
  Ignored = 'IGNORED',
  Info = 'INFO',
  Low = 'LOW',
  Unknown = 'UNKNOWN'
}

/** Can you read, write, or run with this permission */
export enum PermissionType {
  ReadOnly = 'READ_ONLY',
  ReadRun = 'READ_RUN',
  ReadWrite = 'READ_WRITE',
  ReadWriteRun = 'READ_WRITE_RUN',
  Regex = 'REGEX',
  RunOnly = 'RUN_ONLY',
  Tagging = 'TAGGING',
  Unknown = 'UNKNOWN',
  WriteOnly = 'WRITE_ONLY',
  WriteRun = 'WRITE_RUN'
}

/** Details for a permission object */
export type PermissionWithDetails = {
  __typename?: 'PermissionWithDetails';
  permission: AbstractedPermission;
  permissionDetails?: Maybe<PermissionDetails>;
};

export type Query = {
  __typename?: 'Query';
  /** Get a list of activities */
  activities: Array<Activity>;
  /** Fetches the count of activities by a service/identity filter */
  activitiesCount: ActivitiesCount;
  /** Get details about an individual activity. */
  activityDetails?: Maybe<Activity>;
  /** Get the number of alerts */
  alertCounts: Array<AlertCount>;
  /** Get a list of alerts within the current org */
  alerts: Array<Alert>;
  /** Get a list of alerts grouped by the specified field */
  alertsGroupedBy: Array<AlertGroup>;
  allOrgs: Array<Org>;
  /** Get a paginated list of audit logs */
  auditLogs: Array<AuditLog>;
  /** Fetch details about one auth resource. */
  authResourceDetails?: Maybe<AuthResource>;
  /** Get a list of auth resources */
  authResources: Array<AuthResource>;
  classificationRuleset?: Maybe<ClassificationRuleset>;
  /** Fetch all entitlements for a given service identity */
  entitlements?: Maybe<Array<Maybe<AuthResource>>>;
  getAlertDetails: Alert;
  /** Fetches the number of accounts by service and type */
  getDashboardAccountsByServiceAndType: Array<DashboardAccountsByServiceAndType>;
  /** Fetch the number of alerts by name and severity */
  getDashboardAlertsByNameAndSeverity: Array<DashboardAlertsByNameAndSeverity>;
  /** Fetch the number of alerts by service and severity */
  getDashboardAlertsByServiceAndSeverity: Array<DashboardAlertsByServiceAndSeverity>;
  /** Fetch the number of alerts by severity */
  getDashboardAlertsBySeverity: Array<DashboardAlertsBySeverity>;
  /** Fetches the number of authenticators by service and type */
  getDashboardAuthenticatorsByServiceAndType: Array<DashboardAuthenticatorsByServiceAndType>;
  getIngestJob: Job;
  /** Fetches the number of integrated applications by their integrationId */
  getIntegrationCounts: Array<IntegrationCount>;
  /** Get a list of identities within the current org. These represent Non-Human identities. */
  identities: Array<Identity>;
  /** Fetch details about one identity. */
  identityDetails?: Maybe<Identity>;
  /** Get a list of Identity Owners within the current org. These are Users and/or Human-Identities within the org. */
  identityOwners: Array<IdentityOwner>;
  /** Get a list of ingestion jobs within the current org. */
  ingestjobs: Array<Job>;
  itsmWorkspaces?: Maybe<Array<ItsmWorkspace>>;
  /** Get the latest job chain for a given service within the current org */
  latestJobChain?: Maybe<JobChain>;
  me: MeResponse;
  myOrgs: Array<Org>;
  org: Org;
  /** Fetch all permissionSets for an authenticator */
  permissionSets?: Maybe<Array<Maybe<AuthResource>>>;
  /** Fetch all permissions for an authenticator */
  permissions: Array<PermissionWithDetails>;
  /** Gets details about an individual service. */
  serviceDetails?: Maybe<Service>;
  /** Get a list of service identities within the current org. */
  serviceIdentities: Array<ServiceIdentity>;
  /** Get one service identity within the current org. */
  serviceIdentity?: Maybe<ServiceIdentity>;
  /** Gets the count of service identities within the current org. */
  serviceIdentityWidgetCounts: ServiceIdentityCounts;
  /** Retrieves a list of service instance names mapped by their instance IDs for each integrationId */
  serviceInstances: Scalars['JSON']['output'];
  services?: Maybe<Array<Service>>;
  /** Get the count of unassigned (owner or type) identities */
  unassignedIdentitiesCount: Scalars['Int']['output'];
  user: User;
  users?: Maybe<Array<User>>;
};

export type QueryActivitiesArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetActivitiesSort>;
};

export type QueryActivitiesCountArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type QueryActivityDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryAlertCountsArgs = {
  accounts?: Array<Scalars['ObjectID']['input']>;
  assignees?: Array<Scalars['ObjectID']['input']>;
  authResources?: Array<Scalars['ObjectID']['input']>;
  groupBy?: InputMaybe<AlertGroupBy>;
  identities?: Array<Scalars['ObjectID']['input']>;
  integrationIds?: Array<IntegrationId>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  severities?: Array<AlertSeverity>;
  statuses?: Array<AlertStatus>;
  targetTypes?: InputMaybe<Array<AlertTargetType>>;
};

export type QueryAlertsArgs = {
  accounts?: Array<Scalars['ObjectID']['input']>;
  assignees?: Array<Scalars['ObjectID']['input']>;
  authResources?: Array<Scalars['ObjectID']['input']>;
  identities?: Array<Scalars['ObjectID']['input']>;
  integrationIds?: Array<IntegrationId>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  severities?: Array<AlertSeverity>;
  sort?: InputMaybe<GetAlertsSort>;
  statuses?: Array<AlertStatus>;
  targetTypes?: InputMaybe<Array<AlertTargetType>>;
};

export type QueryAlertsGroupedByArgs = {
  accounts?: Array<Scalars['ObjectID']['input']>;
  assignees?: Array<Scalars['ObjectID']['input']>;
  authResources?: Array<Scalars['ObjectID']['input']>;
  groupBy?: InputMaybe<AlertGroupBy>;
  identities?: Array<Scalars['ObjectID']['input']>;
  integrationIds?: Array<IntegrationId>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  severities?: Array<AlertSeverity>;
  sort?: InputMaybe<GetAlertsSort>;
  statuses?: Array<AlertStatus>;
  targetTypes?: InputMaybe<Array<AlertTargetType>>;
};

export type QueryAllOrgsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetOrgsSort>;
};

export type QueryAuditLogsArgs = {
  actorType?: InputMaybe<AuditActorType>;
  eventType?: InputMaybe<AuditEvent>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AuditLogSort>;
  toDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAuthResourceDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryAuthResourcesArgs = {
  authResourceType?: InputMaybe<Array<AuthResourceType>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyEntitlements?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetAuthResourcesSort>;
  status?: InputMaybe<Array<AuthResourceStatus>>;
};

export type QueryEntitlementsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceIdentityId: Scalars['ObjectID']['input'];
  types?: InputMaybe<Array<AuthResourceType>>;
};

export type QueryGetAlertDetailsArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryGetIngestJobArgs = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryGetIntegrationCountsArgs = {
  integrationId?: InputMaybe<IntegrationId>;
};

export type QueryIdentitiesArgs = {
  accountType?: InputMaybe<Array<InputMaybe<IdentityAccountType>>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<IdentityScope>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  sort?: InputMaybe<GetIdentitiesSort>;
  statuses?: InputMaybe<Array<IdentityStatus>>;
};

export type QueryIdentityDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryIdentityOwnersArgs = {
  fetchHumanIdentities?: InputMaybe<Scalars['Boolean']['input']>;
  fetchUsers?: InputMaybe<Scalars['Boolean']['input']>;
  identityOwnersSort?: InputMaybe<GetIdentityOwnersSort>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryIngestjobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  type?: InputMaybe<JobType>;
};

export type QueryItsmWorkspacesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLatestJobChainArgs = {
  serviceId: Scalars['ObjectID']['input'];
  type: JobChainType;
};

export type QueryOrgArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryPermissionSetsArgs = {
  authenticatorId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  serviceIdentityId: Scalars['ObjectID']['input'];
};

export type QueryPermissionsArgs = {
  authenticatorId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  permissionTypes?: InputMaybe<Array<PermissionType>>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceIdentityId: Scalars['ObjectID']['input'];
  severities?: InputMaybe<Array<PermissionSeverity>>;
  showRegexPermissions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryServiceDetailsArgs = {
  _id: Scalars['ObjectID']['input'];
};

export type QueryServiceIdentitiesArgs = {
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  granularTypes?: InputMaybe<Array<GranularType>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<GetServiceIdentitiesSort>;
};

export type QueryServiceIdentityArgs = {
  id: Scalars['ObjectID']['input'];
};

export type QueryServiceIdentityWidgetCountsArgs = {
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  granularTypes?: InputMaybe<Array<GranularType>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
  serviceIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryServicesArgs = {
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  isIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUnassignedIdentitiesCountArgs = {
  unassignedField: UnassignedFieldEnum;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgId?: InputMaybe<Scalars['ObjectID']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetUsersSort>;
  status?: InputMaybe<Array<InputMaybe<UserStatus>>>;
};

/** Details for a role object */
export type RoleDetails = AuthResourceDetails & {
  __typename?: 'RoleDetails';
  authResources: Array<Maybe<Scalars['ObjectID']['output']>>;
  externalType?: Maybe<Scalars['String']['output']>;
  lastInteractiveActivity?: Maybe<Scalars['DateTime']['output']>;
  lastProgrammaticActivity?: Maybe<Scalars['DateTime']['output']>;
  trustedEntities?: Maybe<Array<Maybe<TrustedEntity>>>;
  type: AuthResourceType;
};

/** Salesforce connection details */
export type SalesforceIntegration = Integration & {
  __typename?: 'SalesforceIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  instanceUrl?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** The representation of a third party app to which service identities and resources belong */
export type Service = {
  __typename?: 'Service';
  _id: Scalars['ObjectID']['output'];
  auxiliary?: Maybe<Auxiliary>;
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  inferred?: Maybe<Inferred>;
  instanceName?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Integration>;
  isDefaultITSMService?: Maybe<Scalars['Boolean']['output']>;
  lastActivityIngest?: Maybe<Scalars['DateTime']['output']>;
  org: Org;
  owner?: Maybe<User>;
  serviceIdentities: Array<ServiceIdentity>;
  serviceIdentityCount: Scalars['Int']['output'];
  status: ServiceStatus;
  type?: Maybe<ServiceType>;
};

/** The representation of a third party app to which service identities and resources belong */
export type ServiceServiceIdentitiesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Input body for creating a Service object */
export type ServiceCreateBody = {
  /** The display name for this service */
  displayName: Scalars['String']['input'];
};

/** Service-specific identity details for a given non-human identity */
export type ServiceIdentity = {
  __typename?: 'ServiceIdentity';
  _id: Scalars['ObjectID']['output'];
  /** Service-specific attributes that vary by integration type */
  attributes?: Maybe<Scalars['JSON']['output']>;
  authResources: Array<AuthResource>;
  displayName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<Scalars['String']['output']>;
  granularType?: Maybe<GranularType>;
  identity: IdentityOrError;
  integrationId?: Maybe<IntegrationId>;
  lastActivity?: Maybe<Scalars['DateTime']['output']>;
  lastIngested?: Maybe<Scalars['DateTime']['output']>;
  metadata?: Maybe<ServiceIdentityMetadata>;
  openAlerts: Array<Alert>;
  org: Org;
  owner?: Maybe<User>;
  principal?: Maybe<IdentityPrincipal>;
  scope?: Maybe<ServiceIdentityScope>;
  service: Service;
  status: ServiceIdentityStatus;
  totalCount?: Maybe<Scalars['Int']['output']>;
  type: ServiceIdentityType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** Service-specific identity details for a given non-human identity */
export type ServiceIdentityAuthResourcesArgs = {
  filterOnValidPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetAuthResourcesSort>;
  start?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<AuthResourceType>>;
};

/** The service identity counts displayed on the directory */
export type ServiceIdentityCounts = {
  __typename?: 'ServiceIdentityCounts';
  accountsByService: Array<Maybe<AccountByService>>;
  accountsByType: Array<Maybe<AccountByType>>;
  authenticatorsByType: Array<Maybe<AuthenticatorByType>>;
};

/** The metadata used to populate service identity account details */
export type ServiceIdentityMetadata = {
  __typename?: 'ServiceIdentityMetadata';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  lastInteractiveActivity?: Maybe<Scalars['DateTime']['output']>;
  lastProgrammaticActivity?: Maybe<Scalars['DateTime']['output']>;
};

/** The scope of a service identity */
export enum ServiceIdentityScope {
  InScope = 'IN_SCOPE',
  OutOfScope = 'OUT_OF_SCOPE'
}

/** The status of a service-specific identity */
export enum ServiceIdentityStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Tombstoned = 'TOMBSTONED'
}

/** The type of the service identity */
export enum ServiceIdentityType {
  Inferred = 'INFERRED',
  Integrated = 'INTEGRATED'
}

/** Input body for updating a Service Identity object. */
export type ServiceIdentityUpdateBody = {
  /** ID of the service identity to update the type. */
  _id: Scalars['String']['input'];
  patch: ServiceIdentityUpdatePatch;
};

/** Patch object for updating a service identity */
export type ServiceIdentityUpdatePatch = {
  granularType?: InputMaybe<GranularType>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
  ownerType?: InputMaybe<IdentityOwnerType>;
};

/** A map of serviceId to instanceName for a given integrationId */
export type ServiceInstanceMap = {
  __typename?: 'ServiceInstanceMap';
  instanceName?: Maybe<Scalars['String']['output']>;
  serviceId?: Maybe<Scalars['String']['output']>;
};

/** ServiceNow integration details */
export type ServiceNowIntegration = Integration & {
  __typename?: 'ServiceNowIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The type of owner for a service */
export enum ServiceOwnerType {
  User = 'USER'
}

/** The status of a service within the system */
export enum ServiceStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  Tombstoned = 'TOMBSTONED'
}

/** The type of the service */
export enum ServiceType {
  Auxiliary = 'AUXILIARY',
  Inferred = 'INFERRED',
  Integrated = 'INTEGRATED'
}

/** Input body for updating a Service object */
export type ServiceUpdateBody = {
  /** ID of the service to update the type. */
  _id: Scalars['String']['input'];
  patch: ServiceUpdatePatch;
};

/** Patch object for updating an service */
export type ServiceUpdatePatch = {
  newScopes?: InputMaybe<Array<Scalars['String']['input']>>;
  ownerId?: InputMaybe<Scalars['ObjectID']['input']>;
};

/** Snowflake integration details */
export type SnowflakeIntegration = Integration & {
  __typename?: 'SnowflakeIntegration';
  activeAuthType: IntegrationAuthTypes;
  authFields: Scalars['JSON']['output'];
  authorizedScopes: Array<Scalars['String']['output']>;
  currentScopes?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  integrationId: IntegrationId;
  projectKey?: Maybe<Scalars['String']['output']>;
  status: IntegrationStatus;
  uniqueId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Operations requested by the server available for AD Agents to receive and complete */
export type SyncOperation = {
  __typename?: 'SyncOperation';
  _id: Scalars['ObjectID']['output'];
  operation: SyncOperationType;
  operationParams?: Maybe<OperationParams>;
  org: Org;
  service: Service;
  status: SyncOperationStatus;
  timestamps: OperationTimestamps;
  transactionId: Scalars['String']['output'];
};

/** Fields necessary to create a new sync operation */
export type SyncOperationCreateBody = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  operation: SyncOperationType;
  service: Scalars['ObjectID']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** The state in which a sync operation exists */
export enum SyncOperationStatus {
  Abandoned = 'ABANDONED',
  Acknowledged = 'ACKNOWLEDGED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Pending = 'PENDING',
  Syncing = 'SYNCING'
}

/** The type of agent operation requested */
export enum SyncOperationType {
  AgentlogSync = 'AGENTLOG_SYNC',
  AgentUpdate = 'AGENT_UPDATE',
  DirectorySync = 'DIRECTORY_SYNC',
  EventSync = 'EVENT_SYNC',
  GroupSync = 'GROUP_SYNC'
}

/** A single trusted entity on the role */
export type TrustedEntity = {
  __typename?: 'TrustedEntity';
  /** ExternalId or otherwise identifiable name for the entity */
  entity: Scalars['String']['output'];
  /** EntityType could be other Users and Roles, Federated Entity, or a System */
  entityType?: Maybe<TrustedEntityType>;
  /** Last interactive usage by this entity on the role */
  lastInteractiveUsage?: Maybe<Scalars['DateTime']['output']>;
  /** Last programmatic usage by this entity on the role */
  lastProgrammaticUsage?: Maybe<Scalars['DateTime']['output']>;
};

/** The type of trusted entity */
export enum TrustedEntityType {
  Federated = 'FEDERATED',
  Role = 'ROLE',
  System = 'SYSTEM',
  User = 'USER'
}

/** Unassigned fields to query on */
export enum UnassignedFieldEnum {
  AccountType = 'ACCOUNT_TYPE',
  Owner = 'OWNER'
}

/** A simple type to confirm the updates of multiple alerts */
export type UpdateAlertsResult = {
  __typename?: 'UpdateAlertsResult';
  modifiedCount?: Maybe<Scalars['Int']['output']>;
};

/** A human user who is able to log in and act within Natoma. */
export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectID']['output'];
  /** User data from descope (used for authenticating users). */
  descope: DescopeData;
  displayName?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  loginType: UserLoginType;
  org: Org;
  role: UserRole;
  status: UserStatus;
};

/** The type of login method used by a user */
export enum UserLoginType {
  Disallowed = 'DISALLOWED',
  Local = 'LOCAL',
  Sso = 'SSO'
}

/** The role of a user */
export enum UserRole {
  Admin = 'ADMIN',
  Auditor = 'AUDITOR',
  NoAccess = 'NO_ACCESS'
}

/** The status of an organization */
export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
  OwnerOnly = 'OWNER_ONLY'
}

export type ChangeUserRoleMutationVariables = Exact<{
  userId: Scalars['ObjectID']['input'];
  role: UserRole;
}>;

export type ChangeUserRoleMutation = {
  __typename?: 'Mutation';
  changeUserRole: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
    role: UserRole;
    org: { __typename?: 'Org'; _id: any };
  };
};

export type CreateClassificationRulesetMutationVariables = Exact<{
  body: ClassificationRulesetCreateBody;
}>;

export type CreateClassificationRulesetMutation = {
  __typename?: 'Mutation';
  createClassificationRuleset: {
    __typename?: 'ClassificationRuleset';
    _id: any;
    rules: Array<{
      __typename?: 'ClassificationRule';
      _id: any;
      description?: string | null;
      type: ClassificationRuleType;
      category: MatchType;
      property: string;
      rule: string;
      targetType: ClassificationRuleTargetType;
      scope: IdentityScope;
    } | null>;
  };
};

export type CreateItsmTicketFromAlertMutationVariables = Exact<{
  input: CreateItsmTicketInput;
}>;

export type CreateItsmTicketFromAlertMutation = {
  __typename?: 'Mutation';
  createITSMTicketFromAlert?: {
    __typename?: 'ITSMTicket';
    status: string;
    createdAt: any;
    externalTicketId: string;
    externalTicketURL: string;
    lastSyncedAt: any;
  } | null;
};

export type CreateIngestJobMutationVariables = Exact<{
  input: CreateIngestJobInput;
}>;

export type CreateIngestJobMutation = {
  __typename?: 'Mutation';
  createIngestJob: {
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
  };
};

export type CreateJobChainMutationVariables = Exact<{
  input: CreateJobChainInput;
}>;

export type CreateJobChainMutation = {
  __typename?: 'Mutation';
  createJobChain: Array<{
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
  }>;
};

export type CreateServiceFromIntegrationMutationVariables = Exact<{
  input: CreateIntegrationInput;
}>;

export type CreateServiceFromIntegrationMutation = {
  __typename?: 'Mutation';
  createServiceFromIntegration: {
    __typename?: 'Service';
    _id: any;
    status: ServiceStatus;
    integration?:
      | { __typename: 'AWSIntegration'; status: IntegrationStatus }
      | { __typename: 'ActiveDirectoryIntegration'; status: IntegrationStatus }
      | { __typename: 'EntraIntegration'; status: IntegrationStatus }
      | { __typename: 'GithubIntegration'; status: IntegrationStatus }
      | {
          __typename: 'GoogleWorkspaceIntegration';
          scope?: string | null;
          status: IntegrationStatus;
        }
      | { __typename: 'JiraIntegration'; status: IntegrationStatus }
      | {
          __typename: 'OktaIntegration';
          domain?: string | null;
          status: IntegrationStatus;
        }
      | { __typename: 'SalesforceIntegration'; status: IntegrationStatus }
      | { __typename: 'ServiceNowIntegration'; status: IntegrationStatus }
      | { __typename: 'SnowflakeIntegration'; status: IntegrationStatus }
      | null;
  };
};

export type CreateUserFromIdentityMutationVariables = Exact<{
  input: CreateUserFromIdentityInput;
}>;

export type CreateUserFromIdentityMutation = {
  __typename?: 'Mutation';
  createUserFromIdentity: { __typename?: 'User'; _id: any; email: string };
};

export type DeleteServiceIntegrationMutationVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type DeleteServiceIntegrationMutation = {
  __typename?: 'Mutation';
  deleteServiceIntegration?: {
    __typename?: 'DeleteResult';
    _id?: string | null;
    type?: string | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
    role: UserRole;
    org: { __typename?: 'Org'; _id: any };
  };
};

export type OrgRegistrationFieldsFragment = {
  __typename?: 'Org';
  _id: any;
  displayName?: string | null;
  slug: string;
  status: OrgStatus;
};

export type UserRegistrationFieldsFragment = {
  __typename?: 'User';
  _id: any;
  email: string;
  displayName?: string | null;
  status: UserStatus;
  org: { __typename?: 'Org'; _id: any };
  descope: { __typename?: 'DescopeData'; userId?: string | null };
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteUser: {
    __typename?: 'User';
    _id: any;
    email: string;
    displayName?: string | null;
    status: UserStatus;
    role: UserRole;
    descope: { __typename?: 'DescopeData'; userId?: string | null };
    org: { __typename?: 'Org'; _id: any };
  };
};

export type RegisterMutationVariables = Exact<{
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  orgDisplayName?: InputMaybe<Scalars['String']['input']>;
  orgSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'MeResponse';
    currentOrg?: {
      __typename?: 'Org';
      _id: any;
      displayName?: string | null;
      slug: string;
      status: OrgStatus;
    } | null;
    currentUser?: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
      status: UserStatus;
      org: { __typename?: 'Org'; _id: any };
      descope: { __typename?: 'DescopeData'; userId?: string | null };
    } | null;
  };
};

export type UpdateAlertMutationVariables = Exact<{
  input: AlertUpdateBody;
}>;

export type UpdateAlertMutation = {
  __typename?: 'Mutation';
  updateAlert: { __typename?: 'Alert'; _id: any };
};

export type UpdateAlertsMutationVariables = Exact<{
  input: AlertsUpdateBody;
}>;

export type UpdateAlertsMutation = {
  __typename?: 'Mutation';
  updateAlerts?: {
    __typename?: 'UpdateAlertsResult';
    modifiedCount?: number | null;
  } | null;
};

export type UpdateItsmServiceMutationVariables = Exact<{
  input: ItsmServiceUpdatePatchInput;
}>;

export type UpdateItsmServiceMutation = {
  __typename?: 'Mutation';
  updateITSMService: {
    __typename?: 'Service';
    _id: any;
    integration?:
      | {
          __typename?: 'AWSIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'EntraIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'GithubIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'JiraIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'OktaIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'SalesforceIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'ServiceNowIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | {
          __typename?: 'SnowflakeIntegration';
          integrationId: IntegrationId;
          authFields: any;
        }
      | null;
  };
};

export type UpdateIdentitiesMutationVariables = Exact<{
  input: BulkUpdatesIdentitiesInput;
}>;

export type UpdateIdentitiesMutation = {
  __typename?: 'Mutation';
  updateIdentities: Array<{
    __typename?: 'Identity';
    _id: any;
    email?: string | null;
    displayName?: string | null;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
    } | null;
  }>;
};

export type UpdateIdentityMutationVariables = Exact<{
  input: IdentityUpdateBody;
}>;

export type UpdateIdentityMutation = {
  __typename?: 'Mutation';
  updateIdentity: {
    __typename?: 'Identity';
    _id: any;
    email?: string | null;
    displayName?: string | null;
    org: {
      __typename?: 'Org';
      _id: any;
      displayName?: string | null;
      slug: string;
    };
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      org: {
        __typename?: 'Org';
        _id: any;
        displayName?: string | null;
        slug: string;
      };
    } | null;
    classification?: {
      __typename?: 'Classification';
      accountType?: IdentityAccountType | null;
    } | null;
  };
};

export type UpdateOrgMutationVariables = Exact<{
  input: OrgUpdateBody;
}>;

export type UpdateOrgMutation = {
  __typename?: 'Mutation';
  updateOrg: {
    __typename?: 'Org';
    _id: any;
    displayName?: string | null;
    status: OrgStatus;
    onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
  };
};

export type UpdateServiceIdentityMutationVariables = Exact<{
  input: ServiceIdentityUpdateBody;
}>;

export type UpdateServiceIdentityMutation = {
  __typename?: 'Mutation';
  updateServiceIdentity: {
    __typename?: 'ServiceIdentity';
    _id: any;
    displayName?: string | null;
    org: {
      __typename?: 'Org';
      _id: any;
      displayName?: string | null;
      slug: string;
    };
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
    } | null;
  };
};

export type CurrentOrgFieldsFragment = {
  __typename?: 'Org';
  _id: any;
  slug: string;
  displayName?: string | null;
  status: OrgStatus;
  featureSwitchOverrides: any;
  onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
  defaultITSMService?: {
    __typename?: 'Service';
    _id: any;
    instanceName?: string | null;
    integration?:
      | {
          __typename?: 'AWSIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'EntraIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'GithubIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'JiraIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'OktaIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'SalesforceIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'ServiceNowIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | {
          __typename?: 'SnowflakeIntegration';
          integrationId: IntegrationId;
          projectKey?: string | null;
        }
      | null;
  } | null;
};

export type CurrentUserFieldsFragment = {
  __typename?: 'User';
  _id: any;
  email: string;
  displayName?: string | null;
  status: UserStatus;
};

export type IngestJobFieldsFragment = {
  __typename?: 'Job';
  _id: any;
  completedAt?: any | null;
  queuedAt: any;
  startedAt?: any | null;
  status: JobStatus;
  type: JobType;
  error?: string | null;
  missingScopes?: Array<string> | null;
  triggeredBy?: { __typename?: 'User'; _id: any } | null;
  org: { __typename?: 'Org'; _id: any };
  context?: {
    __typename?: 'JobContext';
    type?: JobContextType | null;
    ref?: any | null;
    service?: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'JiraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ServiceNowIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
    } | null;
  } | null;
  processingMetadata?: {
    __typename?: 'IngestJobProcessingMetadata';
    processedIdentities?: number | null;
    expectedIdentities?: number | null;
  } | null;
};

export type IdentityFieldFragment = {
  __typename?: 'Identity';
  _id: any;
  displayName?: string | null;
  email?: string | null;
  owner?: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
  } | null;
  classification?: {
    __typename?: 'Classification';
    accountType?: IdentityAccountType | null;
    scope?: IdentityScope | null;
  } | null;
  serviceIdentities: Array<{
    __typename?: 'ServiceIdentity';
    _id: any;
    service: {
      __typename?: 'Service';
      displayName: string;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
      inferred?: {
        __typename?: 'Inferred';
        integrationId?: InferredIntegrationId | null;
        integrationLookupId?: string | null;
      } | null;
      auxiliary?: {
        __typename?: 'Auxiliary';
        integrationId?: IntegrationId | null;
      } | null;
    };
  }>;
};

export type IdentityFieldForListFragment = {
  __typename?: 'Identity';
  _id: any;
  displayName?: string | null;
  owner?: {
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
  } | null;
};

export type PermissionSetDetailFieldFragment = {
  __typename?: 'PermissionSetDetails';
  permissions?: Array<{
    __typename?: 'Permission';
    effect?: PermissionEffect | null;
    action: any;
    filter?: any | null;
  } | null> | null;
};

export type GroupDetailFieldFragment = {
  __typename?: 'GroupDetails';
  authResources: Array<any | null>;
};

export type AuthenticatorDetailsFieldFragment = {
  __typename?: 'AuthenticatorDetails';
  serviceIdentityRef: any;
  scopes?: Array<string | null> | null;
  usageDetails: Array<{
    __typename?: 'AuthenticatorUsageDetails';
    name: string;
    lastUsed: any;
    service?: any | null;
    integrationId?: InferredIntegrationId | null;
  } | null>;
};

export type RoleDetailFieldFragment = {
  __typename?: 'RoleDetails';
  authResources: Array<any | null>;
  externalType?: string | null;
  lastProgrammaticActivity?: any | null;
  lastInteractiveActivity?: any | null;
  trustedEntities?: Array<{
    __typename?: 'TrustedEntity';
    entity: string;
    entityType?: TrustedEntityType | null;
    lastInteractiveUsage?: any | null;
    lastProgrammaticUsage?: any | null;
  } | null> | null;
};

export type AlertDetailFieldsFragment = {
  __typename?: 'Alert';
  _id: any;
  issueId?: number | null;
  type: AlertName;
  severity: AlertSeverity;
  status: AlertStatus;
  detectedOn?: any | null;
  org: {
    __typename?: 'Org';
    _id: any;
    slug: string;
    defaultITSMService?: {
      __typename?: 'Service';
      _id: any;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'JiraIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'ServiceNowIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | null;
    } | null;
  };
  assignee: {
    __typename?: 'User';
    _id: any;
    email: string;
    displayName?: string | null;
  };
  target: { __typename?: 'AlertTarget'; type?: AlertTargetType | null };
  identity?: {
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    email?: string | null;
    owner?: {
      __typename?: 'User';
      email: string;
      displayName?: string | null;
    } | null;
  } | null;
  serviceIdentity?: {
    __typename?: 'ServiceIdentity';
    _id: any;
    externalId?: string | null;
    displayName?: string | null;
    username?: string | null;
    granularType?: GranularType | null;
    attributes?: any | null;
    lastActivity?: any | null;
    principal?: { __typename?: 'IdentityPrincipal'; principal: string } | null;
    authResources: Array<{
      __typename?: 'AuthResource';
      _id: any;
      name?: string | null;
      lastActivity?: any | null;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthDetails'; type: AuthResourceType }
        | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    }>;
    service: {
      __typename?: 'Service';
      _id: any;
      instanceName?: string | null;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
    metadata?: {
      __typename?: 'ServiceIdentityMetadata';
      createdAt?: any | null;
      lastProgrammaticActivity?: any | null;
      lastInteractiveActivity?: any | null;
    } | null;
    owner?: {
      __typename?: 'User';
      email: string;
      displayName?: string | null;
    } | null;
  } | null;
  authResource?: {
    __typename?: 'AuthResource';
    _id: any;
    name?: string | null;
    externalId: string;
    createdOn?: any | null;
    lastActivity?: any | null;
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | {
          __typename?: 'AuthenticatorDetails';
          type: AuthResourceType;
          usageDetails: Array<{
            __typename?: 'AuthenticatorUsageDetails';
            service?: any | null;
            integrationId?: InferredIntegrationId | null;
          } | null>;
        }
      | { __typename?: 'GroupDetails'; type: AuthResourceType }
      | { __typename?: 'OAuthDetails'; type: AuthResourceType }
      | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
      | { __typename?: 'RoleDetails'; type: AuthResourceType }
      | null;
  } | null;
  service?: {
    __typename?: 'Service';
    _id: any;
    instanceName?: string | null;
    displayName: string;
    integration?:
      | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
      | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
      | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
      | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
      | null;
  } | null;
  itsmTicket?: {
    __typename?: 'ITSMTicket';
    externalTicketURL: string;
    externalTicketId: string;
    integrationId: string;
    status: string;
  } | null;
};

export type GetActivitiesQueryVariables = Exact<{
  identityId: Array<Scalars['ObjectID']['input']>;
  serviceId: Array<Scalars['ObjectID']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<GetActivitiesSort>;
}>;

export type GetActivitiesQuery = {
  __typename?: 'Query';
  activities: Array<{
    __typename?: 'Activity';
    _id: any;
    actorType: ActorType;
    actorName?: string | null;
    actionType: string;
    timestamp: any;
    org: { __typename?: 'Org'; _id: any };
    service: { __typename?: 'Service'; _id: any };
    identities?: Array<{ __typename?: 'ServiceIdentity'; _id: any }> | null;
  }>;
  activitiesCount: { __typename?: 'ActivitiesCount'; count: number };
};

export type GetActivityDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetActivityDetailsQuery = {
  __typename?: 'Query';
  activityDetails?: {
    __typename?: 'Activity';
    _id: any;
    org: { __typename?: 'Org'; _id: any };
    detail?:
      | {
          __typename?: 'GenericActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'GithubActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'GoogleWorkspaceActivityDetail';
          integrationId: IntegrationId;
          activityId: string;
        }
      | {
          __typename?: 'OktaActivityDetail';
          oktaUserId?: string | null;
          oktaUserDisplayName?: string | null;
          displayMessage?: string | null;
          eventType: string;
          severity: string;
          integrationId: IntegrationId;
          activityId: string;
        }
      | null;
  } | null;
};

export type GetAlertCountsQueryVariables = Exact<{
  accounts?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  assignees?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  identities?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationIds?: InputMaybe<Array<IntegrationId>>;
  severities?: InputMaybe<Array<AlertSeverity>>;
  statuses?: InputMaybe<Array<AlertStatus>>;
  groupBy?: InputMaybe<AlertGroupBy>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAlertCountsQuery = {
  __typename?: 'Query';
  alertCounts: Array<{
    __typename?: 'AlertCount';
    group: string;
    groupId: string;
    count: number;
  }>;
};

export type GetAlertDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetAlertDetailsQuery = {
  __typename?: 'Query';
  getAlertDetails: {
    __typename?: 'Alert';
    _id: any;
    issueId?: number | null;
    type: AlertName;
    severity: AlertSeverity;
    status: AlertStatus;
    detectedOn?: any | null;
    identity?: {
      __typename?: 'Identity';
      _id: any;
      displayName?: string | null;
      email?: string | null;
      owner?: {
        __typename?: 'User';
        displayName?: string | null;
        email: string;
      } | null;
      classification?: {
        __typename?: 'Classification';
        accountType?: IdentityAccountType | null;
      } | null;
      serviceIdentities: Array<{
        __typename?: 'ServiceIdentity';
        _id: any;
        service: {
          __typename?: 'Service';
          displayName: string;
          integration?:
            | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'ActiveDirectoryIntegration';
                integrationId: IntegrationId;
              }
            | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
            | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'GoogleWorkspaceIntegration';
                integrationId: IntegrationId;
              }
            | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
            | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'SalesforceIntegration';
                integrationId: IntegrationId;
              }
            | {
                __typename?: 'ServiceNowIntegration';
                integrationId: IntegrationId;
              }
            | {
                __typename?: 'SnowflakeIntegration';
                integrationId: IntegrationId;
              }
            | null;
          inferred?: {
            __typename?: 'Inferred';
            integrationLookupId?: string | null;
            integrationId?: InferredIntegrationId | null;
          } | null;
        };
      }>;
    } | null;
    itsmTicket?: {
      __typename?: 'ITSMTicket';
      status: string;
      externalTicketId: string;
      externalTicketURL: string;
      lastSyncedAt: any;
      createdAt: any;
      integrationId: string;
    } | null;
    org: {
      __typename?: 'Org';
      _id: any;
      slug: string;
      defaultITSMService?: {
        __typename?: 'Service';
        _id: any;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | null;
      } | null;
    };
    assignee: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
    };
    target: { __typename?: 'AlertTarget'; type?: AlertTargetType | null };
    serviceIdentity?: {
      __typename?: 'ServiceIdentity';
      _id: any;
      externalId?: string | null;
      displayName?: string | null;
      username?: string | null;
      granularType?: GranularType | null;
      attributes?: any | null;
      lastActivity?: any | null;
      principal?: {
        __typename?: 'IdentityPrincipal';
        principal: string;
      } | null;
      authResources: Array<{
        __typename?: 'AuthResource';
        _id: any;
        name?: string | null;
        lastActivity?: any | null;
        details?:
          | { __typename?: 'APITokenDetails'; type: AuthResourceType }
          | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
          | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
          | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
          | { __typename?: 'GroupDetails'; type: AuthResourceType }
          | { __typename?: 'OAuthDetails'; type: AuthResourceType }
          | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
          | { __typename?: 'RoleDetails'; type: AuthResourceType }
          | null;
      }>;
      service: {
        __typename?: 'Service';
        _id: any;
        instanceName?: string | null;
        integration?:
          | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
            }
          | null;
      };
      metadata?: {
        __typename?: 'ServiceIdentityMetadata';
        createdAt?: any | null;
        lastProgrammaticActivity?: any | null;
        lastInteractiveActivity?: any | null;
      } | null;
      owner?: {
        __typename?: 'User';
        email: string;
        displayName?: string | null;
      } | null;
    } | null;
    authResource?: {
      __typename?: 'AuthResource';
      _id: any;
      name?: string | null;
      externalId: string;
      createdOn?: any | null;
      lastActivity?: any | null;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | {
            __typename?: 'AuthenticatorDetails';
            type: AuthResourceType;
            usageDetails: Array<{
              __typename?: 'AuthenticatorUsageDetails';
              service?: any | null;
              integrationId?: InferredIntegrationId | null;
            } | null>;
          }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthDetails'; type: AuthResourceType }
        | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    } | null;
    service?: {
      __typename?: 'Service';
      _id: any;
      instanceName?: string | null;
      displayName: string;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    } | null;
  };
};

export type GetAlertsQueryVariables = Exact<{
  severities?: InputMaybe<Array<AlertSeverity>>;
  assignees?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationIds?: InputMaybe<Array<IntegrationId>>;
  accounts?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  identities?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  authResources?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  statuses?: InputMaybe<Array<AlertStatus>>;
  targetTypes?: InputMaybe<Array<AlertTargetType>>;
  q?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetAlertsSort>;
}>;

export type GetAlertsQuery = {
  __typename?: 'Query';
  alerts: Array<{
    __typename?: 'Alert';
    _id: any;
    issueId?: number | null;
    type: AlertName;
    severity: AlertSeverity;
    status: AlertStatus;
    detectedOn?: any | null;
    org: {
      __typename?: 'Org';
      _id: any;
      slug: string;
      defaultITSMService?: {
        __typename?: 'Service';
        _id: any;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | null;
      } | null;
    };
    assignee: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
    };
    target: { __typename?: 'AlertTarget'; type?: AlertTargetType | null };
    identity?: {
      __typename?: 'Identity';
      _id: any;
      displayName?: string | null;
      email?: string | null;
      owner?: {
        __typename?: 'User';
        email: string;
        displayName?: string | null;
      } | null;
    } | null;
    serviceIdentity?: {
      __typename?: 'ServiceIdentity';
      _id: any;
      externalId?: string | null;
      displayName?: string | null;
      username?: string | null;
      granularType?: GranularType | null;
      attributes?: any | null;
      lastActivity?: any | null;
      principal?: {
        __typename?: 'IdentityPrincipal';
        principal: string;
      } | null;
      authResources: Array<{
        __typename?: 'AuthResource';
        _id: any;
        name?: string | null;
        lastActivity?: any | null;
        details?:
          | { __typename?: 'APITokenDetails'; type: AuthResourceType }
          | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
          | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
          | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
          | { __typename?: 'GroupDetails'; type: AuthResourceType }
          | { __typename?: 'OAuthDetails'; type: AuthResourceType }
          | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
          | { __typename?: 'RoleDetails'; type: AuthResourceType }
          | null;
      }>;
      service: {
        __typename?: 'Service';
        _id: any;
        instanceName?: string | null;
        integration?:
          | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
            }
          | null;
      };
      metadata?: {
        __typename?: 'ServiceIdentityMetadata';
        createdAt?: any | null;
        lastProgrammaticActivity?: any | null;
        lastInteractiveActivity?: any | null;
      } | null;
      owner?: {
        __typename?: 'User';
        email: string;
        displayName?: string | null;
      } | null;
    } | null;
    authResource?: {
      __typename?: 'AuthResource';
      _id: any;
      name?: string | null;
      externalId: string;
      createdOn?: any | null;
      lastActivity?: any | null;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | {
            __typename?: 'AuthenticatorDetails';
            type: AuthResourceType;
            usageDetails: Array<{
              __typename?: 'AuthenticatorUsageDetails';
              service?: any | null;
              integrationId?: InferredIntegrationId | null;
            } | null>;
          }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthDetails'; type: AuthResourceType }
        | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    } | null;
    service?: {
      __typename?: 'Service';
      _id: any;
      instanceName?: string | null;
      displayName: string;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    } | null;
    itsmTicket?: {
      __typename?: 'ITSMTicket';
      externalTicketURL: string;
      externalTicketId: string;
      integrationId: string;
      status: string;
    } | null;
  }>;
};

export type AlertsGroupedByQueryVariables = Exact<{
  severities?: InputMaybe<Array<AlertSeverity>>;
  assignees?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationIds?: InputMaybe<Array<IntegrationId>>;
  accounts?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  identities?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  statuses?: InputMaybe<Array<AlertStatus>>;
  groupBy?: InputMaybe<AlertGroupBy>;
  q?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetAlertsSort>;
}>;

export type AlertsGroupedByQuery = {
  __typename?: 'Query';
  alertsGroupedBy: Array<{
    __typename?: 'AlertGroup';
    groupBy: AlertGroupBy;
    total: number;
    integrationIds?: Array<IntegrationId> | null;
    assignees?: Array<any> | null;
    groupValue: {
      __typename?: 'AlertGroupByValue';
      name?: AlertName | null;
      integrationId?: IntegrationId | null;
      identity?: any | null;
    };
    alerts: Array<{
      __typename?: 'Alert';
      _id: any;
      issueId?: number | null;
      type: AlertName;
      severity: AlertSeverity;
      status: AlertStatus;
      detectedOn?: any | null;
      org: {
        __typename?: 'Org';
        _id: any;
        slug: string;
        defaultITSMService?: {
          __typename?: 'Service';
          _id: any;
          integration?:
            | {
                __typename?: 'AWSIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'ActiveDirectoryIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'EntraIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'GithubIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'GoogleWorkspaceIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'JiraIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'OktaIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'SalesforceIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'ServiceNowIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | {
                __typename?: 'SnowflakeIntegration';
                integrationId: IntegrationId;
                projectKey?: string | null;
              }
            | null;
        } | null;
      };
      assignee: {
        __typename?: 'User';
        _id: any;
        email: string;
        displayName?: string | null;
      };
      target: { __typename?: 'AlertTarget'; type?: AlertTargetType | null };
      identity?: {
        __typename?: 'Identity';
        _id: any;
        displayName?: string | null;
        email?: string | null;
        owner?: {
          __typename?: 'User';
          email: string;
          displayName?: string | null;
        } | null;
      } | null;
      serviceIdentity?: {
        __typename?: 'ServiceIdentity';
        _id: any;
        externalId?: string | null;
        displayName?: string | null;
        username?: string | null;
        granularType?: GranularType | null;
        attributes?: any | null;
        lastActivity?: any | null;
        principal?: {
          __typename?: 'IdentityPrincipal';
          principal: string;
        } | null;
        authResources: Array<{
          __typename?: 'AuthResource';
          _id: any;
          name?: string | null;
          lastActivity?: any | null;
          details?:
            | { __typename?: 'APITokenDetails'; type: AuthResourceType }
            | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
            | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
            | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
            | { __typename?: 'GroupDetails'; type: AuthResourceType }
            | { __typename?: 'OAuthDetails'; type: AuthResourceType }
            | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
            | { __typename?: 'RoleDetails'; type: AuthResourceType }
            | null;
        }>;
        service: {
          __typename?: 'Service';
          _id: any;
          instanceName?: string | null;
          integration?:
            | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'ActiveDirectoryIntegration';
                integrationId: IntegrationId;
              }
            | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
            | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'GoogleWorkspaceIntegration';
                integrationId: IntegrationId;
              }
            | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
            | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
            | {
                __typename?: 'SalesforceIntegration';
                integrationId: IntegrationId;
              }
            | {
                __typename?: 'ServiceNowIntegration';
                integrationId: IntegrationId;
              }
            | {
                __typename?: 'SnowflakeIntegration';
                integrationId: IntegrationId;
              }
            | null;
        };
        metadata?: {
          __typename?: 'ServiceIdentityMetadata';
          createdAt?: any | null;
          lastProgrammaticActivity?: any | null;
          lastInteractiveActivity?: any | null;
        } | null;
        owner?: {
          __typename?: 'User';
          email: string;
          displayName?: string | null;
        } | null;
      } | null;
      authResource?: {
        __typename?: 'AuthResource';
        _id: any;
        name?: string | null;
        externalId: string;
        createdOn?: any | null;
        lastActivity?: any | null;
        details?:
          | { __typename?: 'APITokenDetails'; type: AuthResourceType }
          | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
          | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
          | {
              __typename?: 'AuthenticatorDetails';
              type: AuthResourceType;
              usageDetails: Array<{
                __typename?: 'AuthenticatorUsageDetails';
                service?: any | null;
                integrationId?: InferredIntegrationId | null;
              } | null>;
            }
          | { __typename?: 'GroupDetails'; type: AuthResourceType }
          | { __typename?: 'OAuthDetails'; type: AuthResourceType }
          | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
          | { __typename?: 'RoleDetails'; type: AuthResourceType }
          | null;
      } | null;
      service?: {
        __typename?: 'Service';
        _id: any;
        instanceName?: string | null;
        displayName: string;
        integration?:
          | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
            }
          | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
          | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
            }
          | null;
      } | null;
      itsmTicket?: {
        __typename?: 'ITSMTicket';
        externalTicketURL: string;
        externalTicketId: string;
        integrationId: string;
        status: string;
      } | null;
    }>;
    severities?: Array<{
      __typename?: 'AlertSeverityCount';
      severity: AlertSeverity;
      count: number;
    }> | null;
    assigneeObjects?: Array<{
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
    }> | null;
    identity?: {
      __typename?: 'Identity';
      _id: any;
      displayName?: string | null;
      email?: string | null;
    } | null;
  }>;
};

export type GetAuditLogsQueryVariables = Exact<{
  eventType?: InputMaybe<AuditEvent>;
  actorType?: InputMaybe<AuditActorType>;
  fromDate?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<AuditLogSort>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAuditLogsQuery = {
  __typename?: 'Query';
  auditLogs: Array<{
    __typename?: 'AuditLog';
    _id: any;
    timestamp: any;
    event: AuditEvent;
    actor: {
      __typename?: 'AuditLogActorDetails';
      type: AuditActorType;
      details?: {
        __typename?: 'User';
        email: string;
        displayName?: string | null;
      } | null;
    };
    targets?: Array<{
      __typename?: 'AuditLogTargetDetails';
      type: AuditTargetType;
      details?:
        | { __typename?: 'Alert'; issueId?: number | null; type: AlertName }
        | { __typename?: 'User'; email: string; displayName?: string | null }
        | null;
    }> | null;
  }>;
};

export type GetAuthResourceDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetAuthResourceDetailsQuery = {
  __typename?: 'Query';
  authResourceDetails?: {
    __typename?: 'AuthResource';
    _id: any;
    status: AuthResourceStatus;
    externalId: string;
    integrationId: IntegrationId;
    name?: string | null;
    createdOn?: any | null;
    lastActivity?: any | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      instanceName?: string | null;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | {
          __typename?: 'AuthenticatorDetails';
          type: AuthResourceType;
          serviceIdentityRef: any;
          scopes?: Array<string | null> | null;
          usageDetails: Array<{
            __typename?: 'AuthenticatorUsageDetails';
            name: string;
            lastUsed: any;
            service?: any | null;
            integrationId?: InferredIntegrationId | null;
          } | null>;
        }
      | {
          __typename?: 'GroupDetails';
          type: AuthResourceType;
          authResources: Array<any | null>;
        }
      | { __typename?: 'OAuthDetails'; type: AuthResourceType }
      | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
      | {
          __typename?: 'RoleDetails';
          type: AuthResourceType;
          authResources: Array<any | null>;
          externalType?: string | null;
          lastProgrammaticActivity?: any | null;
          lastInteractiveActivity?: any | null;
          trustedEntities?: Array<{
            __typename?: 'TrustedEntity';
            entity: string;
            entityType?: TrustedEntityType | null;
            lastInteractiveUsage?: any | null;
            lastProgrammaticUsage?: any | null;
          } | null> | null;
        }
      | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      externalId?: string | null;
      displayName?: string | null;
      granularType?: GranularType | null;
      lastActivity?: any | null;
      principal?: {
        __typename?: 'IdentityPrincipal';
        principal: string;
      } | null;
      owner?: {
        __typename?: 'User';
        _id: any;
        status: UserStatus;
        displayName?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetAuthResourcesQueryVariables = Exact<{
  authResourceType?: InputMaybe<Array<AuthResourceType>>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  status?: InputMaybe<Array<AuthResourceStatus>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  q?: InputMaybe<Scalars['String']['input']>;
  onlyEntitlements?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetAuthResourcesQuery = {
  __typename?: 'Query';
  authResources: Array<{
    __typename?: 'AuthResource';
    _id: any;
    status: AuthResourceStatus;
    externalId: string;
    lastActivity?: any | null;
    name?: string | null;
    integrationId: IntegrationId;
    service: {
      __typename?: 'Service';
      _id: any;
      instanceName?: string | null;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | {
          __typename?: 'AuthenticatorDetails';
          type: AuthResourceType;
          serviceIdentityRef: any;
          scopes?: Array<string | null> | null;
          usageDetails: Array<{
            __typename?: 'AuthenticatorUsageDetails';
            name: string;
            lastUsed: any;
            service?: any | null;
            integrationId?: InferredIntegrationId | null;
          } | null>;
        }
      | {
          __typename?: 'GroupDetails';
          type: AuthResourceType;
          authResources: Array<any | null>;
        }
      | { __typename?: 'OAuthDetails'; type: AuthResourceType }
      | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
      | {
          __typename?: 'RoleDetails';
          type: AuthResourceType;
          authResources: Array<any | null>;
          externalType?: string | null;
          lastProgrammaticActivity?: any | null;
          lastInteractiveActivity?: any | null;
          trustedEntities?: Array<{
            __typename?: 'TrustedEntity';
            entity: string;
            entityType?: TrustedEntityType | null;
            lastInteractiveUsage?: any | null;
            lastProgrammaticUsage?: any | null;
          } | null> | null;
        }
      | null;
  }>;
};

export type GetClassificationRulesetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetClassificationRulesetQuery = {
  __typename?: 'Query';
  classificationRuleset?: {
    __typename?: 'ClassificationRuleset';
    _id: any;
    createdAt: any;
    updatedAt: any;
    rules: Array<{
      __typename?: 'ClassificationRule';
      _id: any;
      description?: string | null;
      type: ClassificationRuleType;
      category: MatchType;
      property: string;
      rule: string;
      targetType: ClassificationRuleTargetType;
      scope: IdentityScope;
    } | null>;
  } | null;
};

export type GetDashboardAccountsByServiceAndTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDashboardAccountsByServiceAndTypeQuery = {
  __typename?: 'Query';
  getDashboardAccountsByServiceAndType: Array<{
    __typename?: 'DashboardAccountsByServiceAndType';
    integrationId: IntegrationId;
    counts: any;
  }>;
};

export type GetDashboardAlertsByNameAndSeverityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDashboardAlertsByNameAndSeverityQuery = {
  __typename?: 'Query';
  getDashboardAlertsByNameAndSeverity: Array<{
    __typename?: 'DashboardAlertsByNameAndSeverity';
    alertName: AlertName;
    counts: any;
    total: number;
  }>;
};

export type GetDashboardAlertsByServiceAndSeverityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDashboardAlertsByServiceAndSeverityQuery = {
  __typename?: 'Query';
  getDashboardAlertsByServiceAndSeverity: Array<{
    __typename?: 'DashboardAlertsByServiceAndSeverity';
    counts: any;
    total: number;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      instanceName?: string | null;
      integration?:
        | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
          }
        | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
        | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
        | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
        | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
        | null;
    };
  }>;
};

export type GetDashboardAlertsBySeverityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDashboardAlertsBySeverityQuery = {
  __typename?: 'Query';
  getDashboardAlertsBySeverity: Array<{
    __typename?: 'DashboardAlertsBySeverity';
    severity: AlertSeverity;
    count: number;
  }>;
};

export type GetDashboardAuthenticatorsByServiceAndTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetDashboardAuthenticatorsByServiceAndTypeQuery = {
  __typename?: 'Query';
  getDashboardAuthenticatorsByServiceAndType: Array<{
    __typename?: 'DashboardAuthenticatorsByServiceAndType';
    integrationId: IntegrationId;
    counts: any;
  }>;
};

export type GetItsmWorkspacesQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetItsmWorkspacesQuery = {
  __typename?: 'Query';
  itsmWorkspaces?: Array<{
    __typename?: 'ITSMWorkspace';
    projectId: string;
    projectKey: string;
    projectName: string;
  }> | null;
};

export type GetIdentitiesQueryVariables = Exact<{
  serviceId: Array<Scalars['ObjectID']['input']>;
  integrationId: Array<IntegrationId>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sort: GetIdentitiesSort;
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetIdentitiesQuery = {
  __typename?: 'Query';
  identities: Array<{
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    servicesCount?: number | null;
    status: IdentityStatus;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      };
    }>;
  }>;
};

export type GetAllIdentitiesQueryVariables = Exact<{
  statuses?: InputMaybe<Array<IdentityStatus>>;
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetIdentitiesSort>;
  q?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Array<InputMaybe<IdentityAccountType>>>;
  owners?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']['input']>>>;
  scope?: InputMaybe<IdentityScope>;
}>;

export type GetAllIdentitiesQuery = {
  __typename?: 'Query';
  identities: Array<{
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    email?: string | null;
    status: IdentityStatus;
    servicesCount?: number | null;
    classification?: {
      __typename?: 'Classification';
      accountType?: IdentityAccountType | null;
      decidedAt: any;
      scope?: IdentityScope | null;
    } | null;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      status: UserStatus;
    } | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      externalId?: string | null;
      displayName?: string | null;
      type: ServiceIdentityType;
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
        inferred?: {
          __typename?: 'Inferred';
          integrationLookupId?: string | null;
          integrationId?: InferredIntegrationId | null;
        } | null;
      };
      authResources: Array<{
        __typename?: 'AuthResource';
        _id: any;
        name?: string | null;
        externalId: string;
        details?:
          | { __typename?: 'APITokenDetails'; type: AuthResourceType }
          | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
          | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
          | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
          | { __typename?: 'GroupDetails'; type: AuthResourceType }
          | { __typename?: 'OAuthDetails'; type: AuthResourceType }
          | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
          | { __typename?: 'RoleDetails'; type: AuthResourceType }
          | null;
      }>;
    }>;
  }>;
};

export type GetIdentityDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetIdentityDetailsQuery = {
  __typename?: 'Query';
  identityDetails?: {
    __typename?: 'Identity';
    _id: any;
    displayName?: string | null;
    servicesCount?: number | null;
    status: IdentityStatus;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
      status: UserStatus;
    } | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      status: ServiceIdentityStatus;
      service: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        status: ServiceStatus;
        lastActivityIngest?: any | null;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
              authorizedScopes: Array<string>;
              activeAuthType: IntegrationAuthTypes;
              status: IntegrationStatus;
            }
          | null;
      };
    }>;
    classification?: {
      __typename?: 'Classification';
      decidedAt: any;
      accountType?: IdentityAccountType | null;
    } | null;
  } | null;
};

export type GetIdentityOwnersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  identityOwnersSort?: InputMaybe<GetIdentityOwnersSort>;
  fetchUsers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetIdentityOwnersQuery = {
  __typename?: 'Query';
  identityOwners: Array<{
    __typename?: 'IdentityOwner';
    _id: any;
    displayName?: string | null;
    email?: string | null;
    ownerType?: IdentityOwnerType | null;
    org: { __typename?: 'Org'; _id: any };
  }>;
};

export type IngestjobsQueryVariables = Exact<{
  serviceId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  type?: InputMaybe<JobType>;
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type IngestjobsQuery = {
  __typename?: 'Query';
  ingestjobs: Array<{
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    error?: string | null;
    missingScopes?: Array<string> | null;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
    org: { __typename?: 'Org'; _id: any };
    context?: {
      __typename?: 'JobContext';
      type?: JobContextType | null;
      ref?: any | null;
      service?: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      } | null;
    } | null;
    processingMetadata?: {
      __typename?: 'IngestJobProcessingMetadata';
      processedIdentities?: number | null;
      expectedIdentities?: number | null;
    } | null;
  }>;
};

export type IngestjobQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type IngestjobQuery = {
  __typename?: 'Query';
  getIngestJob: {
    __typename?: 'Job';
    _id: any;
    completedAt?: any | null;
    queuedAt: any;
    startedAt?: any | null;
    status: JobStatus;
    type: JobType;
    error?: string | null;
    missingScopes?: Array<string> | null;
    triggeredBy?: { __typename?: 'User'; _id: any } | null;
    org: { __typename?: 'Org'; _id: any };
    context?: {
      __typename?: 'JobContext';
      type?: JobContextType | null;
      ref?: any | null;
      service?: {
        __typename?: 'Service';
        _id: any;
        displayName: string;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              description?: string | null;
            }
          | null;
      } | null;
    } | null;
    processingMetadata?: {
      __typename?: 'IngestJobProcessingMetadata';
      processedIdentities?: number | null;
      expectedIdentities?: number | null;
    } | null;
  };
};

export type GetIntegrationCountsQueryVariables = Exact<{
  integrationId?: InputMaybe<IntegrationId>;
}>;

export type GetIntegrationCountsQuery = {
  __typename?: 'Query';
  getIntegrationCounts: Array<{
    __typename?: 'IntegrationCount';
    integrationId: IntegrationId;
    count: number;
  }>;
};

export type LatestJobChainQueryVariables = Exact<{
  serviceId: Scalars['ObjectID']['input'];
  type: JobChainType;
}>;

export type LatestJobChainQuery = {
  __typename?: 'Query';
  latestJobChain?: {
    __typename?: 'JobChain';
    _id: any;
    startedAt?: any | null;
    completedAt?: any | null;
    status: JobStatus;
    error?: string | null;
    missingScopes?: Array<string> | null;
  } | null;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'MeResponse';
    currentOrg?: {
      __typename?: 'Org';
      _id: any;
      slug: string;
      displayName?: string | null;
      status: OrgStatus;
      featureSwitchOverrides: any;
      onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
      defaultITSMService?: {
        __typename?: 'Service';
        _id: any;
        instanceName?: string | null;
        integration?:
          | {
              __typename?: 'AWSIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ActiveDirectoryIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'EntraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GithubIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'GoogleWorkspaceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'JiraIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'OktaIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SalesforceIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'ServiceNowIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | {
              __typename?: 'SnowflakeIntegration';
              integrationId: IntegrationId;
              projectKey?: string | null;
            }
          | null;
      } | null;
    } | null;
    currentUser?: {
      __typename?: 'User';
      _id: any;
      email: string;
      displayName?: string | null;
      status: UserStatus;
    } | null;
  };
};

export type GetMyOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyOrgsQuery = {
  __typename?: 'Query';
  myOrgs: Array<{
    __typename?: 'Org';
    _id: any;
    slug: string;
    displayName?: string | null;
    status: OrgStatus;
    featureSwitchOverrides: any;
    onboarding?: { __typename?: 'Onboarding'; connectedIDP: boolean } | null;
    defaultITSMService?: {
      __typename?: 'Service';
      _id: any;
      instanceName?: string | null;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'JiraIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'ServiceNowIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            projectKey?: string | null;
          }
        | null;
    } | null;
  }>;
};

export type GetPermissionSetsQueryVariables = Exact<{
  serviceIdentityId: Scalars['ObjectID']['input'];
  authenticatorId: Scalars['ObjectID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPermissionSetsQuery = {
  __typename?: 'Query';
  permissionSets?: Array<{
    __typename?: 'AuthResource';
    _id: any;
    externalId: string;
    name?: string | null;
    createdOn?: any | null;
    lastActivity?: any | null;
    details?:
      | { __typename?: 'APITokenDetails'; type: AuthResourceType }
      | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
      | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
      | {
          __typename?: 'AuthenticatorDetails';
          type: AuthResourceType;
          serviceIdentityRef: any;
          scopes?: Array<string | null> | null;
          usageDetails: Array<{
            __typename?: 'AuthenticatorUsageDetails';
            name: string;
            lastUsed: any;
            service?: any | null;
            integrationId?: InferredIntegrationId | null;
          } | null>;
        }
      | { __typename?: 'GroupDetails'; type: AuthResourceType }
      | { __typename?: 'OAuthDetails'; type: AuthResourceType }
      | {
          __typename?: 'PermissionSetDetails';
          type: AuthResourceType;
          permissions?: Array<{
            __typename?: 'Permission';
            effect?: PermissionEffect | null;
            action: any;
            filter?: any | null;
          } | null> | null;
        }
      | { __typename?: 'RoleDetails'; type: AuthResourceType }
      | null;
  } | null> | null;
};

export type GetPermissionsQueryVariables = Exact<{
  serviceIdentityId: Scalars['ObjectID']['input'];
  authenticatorId: Scalars['ObjectID']['input'];
  severities?: InputMaybe<Array<PermissionSeverity>>;
  showRegexPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  permissionTypes?: InputMaybe<Array<PermissionType>>;
  q?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPermissionsQuery = {
  __typename?: 'Query';
  permissions: Array<{
    __typename?: 'PermissionWithDetails';
    permission: {
      __typename?: 'AbstractedPermission';
      action: any;
      allowFilter: Array<string>;
      denyFilter: Array<string>;
      path?: Array<Array<string>> | null;
    };
    permissionDetails?: {
      __typename?: 'PermissionDetails';
      name: string;
      description?: string | null;
      severity?: PermissionSeverity | null;
      type?: PermissionType | null;
      parentPermission?: string | null;
    } | null;
  }>;
};

export type GetServiceDetailsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetServiceDetailsQuery = {
  __typename?: 'Query';
  serviceDetails?: {
    __typename?: 'Service';
    _id: any;
    displayName: string;
    status: ServiceStatus;
    integration?:
      | { __typename?: 'AWSIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'EntraIntegration'; integrationId: IntegrationId }
      | { __typename?: 'GithubIntegration'; integrationId: IntegrationId }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
        }
      | { __typename?: 'JiraIntegration'; integrationId: IntegrationId }
      | { __typename?: 'OktaIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SalesforceIntegration'; integrationId: IntegrationId }
      | { __typename?: 'ServiceNowIntegration'; integrationId: IntegrationId }
      | { __typename?: 'SnowflakeIntegration'; integrationId: IntegrationId }
      | null;
    serviceIdentities: Array<{
      __typename?: 'ServiceIdentity';
      _id: any;
      status: ServiceIdentityStatus;
      identity:
        | { __typename?: 'Error'; message: string }
        | {
            __typename?: 'Identity';
            _id: any;
            displayName?: string | null;
            email?: string | null;
            classification?: {
              __typename?: 'Classification';
              decidedAt: any;
              accountType?: IdentityAccountType | null;
            } | null;
            owner?: {
              __typename?: 'User';
              _id: any;
              displayName?: string | null;
              email: string;
              status: UserStatus;
            } | null;
          };
    }>;
  } | null;
};

export type GetServiceIdentityCountsQueryVariables = Exact<{
  _id: Scalars['ObjectID']['input'];
}>;

export type GetServiceIdentityCountsQuery = {
  __typename?: 'Query';
  serviceDetails?: {
    __typename?: 'Service';
    serviceIdentityCount: number;
  } | null;
};

export type GetAllServiceIdentitiesQueryVariables = Exact<{
  serviceIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  granularTypes?: InputMaybe<Array<GranularType>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<GetServiceIdentitiesSort>;
  q?: InputMaybe<Scalars['String']['input']>;
  filterOnValidPermissions?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetAllServiceIdentitiesQuery = {
  __typename?: 'Query';
  serviceIdentities: Array<{
    __typename?: 'ServiceIdentity';
    _id: any;
    externalId?: string | null;
    type: ServiceIdentityType;
    displayName?: string | null;
    lastActivity?: any | null;
    granularType?: GranularType | null;
    totalCount?: number | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      instanceName?: string | null;
      type?: ServiceType | null;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'JiraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ServiceNowIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
      inferred?: {
        __typename?: 'Inferred';
        integrationId?: InferredIntegrationId | null;
        integrationLookupId?: string | null;
      } | null;
      auxiliary?: {
        __typename?: 'Auxiliary';
        integrationId?: IntegrationId | null;
      } | null;
    };
    identity:
      | { __typename?: 'Error' }
      | {
          __typename?: 'Identity';
          _id: any;
          displayName?: string | null;
          owner?: {
            __typename?: 'User';
            _id: any;
            displayName?: string | null;
            email: string;
            status: UserStatus;
          } | null;
        };
    principal?: { __typename?: 'IdentityPrincipal'; principal: string } | null;
    authResources: Array<{
      __typename?: 'AuthResource';
      _id: any;
      name?: string | null;
      externalId: string;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthDetails'; type: AuthResourceType }
        | { __typename?: 'PermissionSetDetails'; type: AuthResourceType }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    }>;
    openAlerts: Array<{
      __typename?: 'Alert';
      type: AlertName;
      severity: AlertSeverity;
    }>;
  }>;
};

export type GetServiceIdentityAuthResourcesQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
  start?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<AuthResourceType>>;
  filterOnValidPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<GetAuthResourcesSort>;
}>;

export type GetServiceIdentityAuthResourcesQuery = {
  __typename?: 'Query';
  serviceIdentity?: {
    __typename?: 'ServiceIdentity';
    _id: any;
    authResources: Array<{
      __typename?: 'AuthResource';
      _id: any;
      externalId: string;
      name?: string | null;
      createdOn?: any | null;
      lastActivity?: any | null;
      details?:
        | { __typename?: 'APITokenDetails'; type: AuthResourceType }
        | { __typename?: 'AccessKeyDetails'; type: AuthResourceType }
        | { __typename?: 'AppPasswordDetails'; type: AuthResourceType }
        | { __typename?: 'AuthenticatorDetails'; type: AuthResourceType }
        | { __typename?: 'GroupDetails'; type: AuthResourceType }
        | { __typename?: 'OAuthDetails'; type: AuthResourceType }
        | {
            __typename?: 'PermissionSetDetails';
            type: AuthResourceType;
            permissions?: Array<{
              __typename?: 'Permission';
              effect?: PermissionEffect | null;
              action: any;
              filter?: any | null;
            } | null> | null;
          }
        | { __typename?: 'RoleDetails'; type: AuthResourceType }
        | null;
    }>;
  } | null;
};

export type GetServiceIdentityDetailsQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;

export type GetServiceIdentityDetailsQuery = {
  __typename?: 'Query';
  serviceIdentity?: {
    __typename?: 'ServiceIdentity';
    _id: any;
    externalId?: string | null;
    displayName?: string | null;
    username?: string | null;
    lastActivity?: any | null;
    granularType?: GranularType | null;
    integrationId?: IntegrationId | null;
    attributes?: any | null;
    owner?: {
      __typename?: 'User';
      _id: any;
      displayName?: string | null;
      email: string;
    } | null;
    service: {
      __typename?: 'Service';
      _id: any;
      displayName: string;
      instanceName?: string | null;
      type?: ServiceType | null;
      integration?:
        | {
            __typename?: 'AWSIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ActiveDirectoryIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'EntraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GithubIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'GoogleWorkspaceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'JiraIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'OktaIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SalesforceIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'ServiceNowIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | {
            __typename?: 'SnowflakeIntegration';
            integrationId: IntegrationId;
            description?: string | null;
          }
        | null;
      inferred?: {
        __typename?: 'Inferred';
        integrationId?: InferredIntegrationId | null;
        integrationLookupId?: string | null;
      } | null;
      auxiliary?: {
        __typename?: 'Auxiliary';
        integrationId?: IntegrationId | null;
      } | null;
    };
    identity:
      | { __typename?: 'Error' }
      | {
          __typename?: 'Identity';
          _id: any;
          displayName?: string | null;
          email?: string | null;
          owner?: {
            __typename?: 'User';
            _id: any;
            displayName?: string | null;
            email: string;
            status: UserStatus;
          } | null;
          classification?: {
            __typename?: 'Classification';
            accountType?: IdentityAccountType | null;
            scope?: IdentityScope | null;
          } | null;
          serviceIdentities: Array<{
            __typename?: 'ServiceIdentity';
            _id: any;
            service: {
              __typename?: 'Service';
              displayName: string;
              integration?:
                | {
                    __typename?: 'AWSIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'ActiveDirectoryIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'EntraIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'GithubIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'GoogleWorkspaceIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'JiraIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'OktaIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'SalesforceIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'ServiceNowIntegration';
                    integrationId: IntegrationId;
                  }
                | {
                    __typename?: 'SnowflakeIntegration';
                    integrationId: IntegrationId;
                  }
                | null;
              inferred?: {
                __typename?: 'Inferred';
                integrationId?: InferredIntegrationId | null;
                integrationLookupId?: string | null;
              } | null;
              auxiliary?: {
                __typename?: 'Auxiliary';
                integrationId?: IntegrationId | null;
              } | null;
            };
          }>;
        };
    metadata?: {
      __typename?: 'ServiceIdentityMetadata';
      createdAt?: any | null;
      lastActiveAt?: any | null;
      lastProgrammaticActivity?: any | null;
      lastInteractiveActivity?: any | null;
    } | null;
  } | null;
};

export type GetServiceIdentityWidgetCountsQueryVariables = Exact<{
  serviceIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  authResourceTypes?: InputMaybe<Array<AuthResourceType>>;
  owners?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  granularTypes?: InputMaybe<Array<GranularType>>;
  q?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetServiceIdentityWidgetCountsQuery = {
  __typename?: 'Query';
  serviceIdentityWidgetCounts: {
    __typename?: 'ServiceIdentityCounts';
    accountsByType: Array<{
      __typename?: 'AccountByType';
      _id: GranularType;
      count: number;
    } | null>;
    accountsByService: Array<{
      __typename?: 'AccountByService';
      _id: IntegrationId;
      count: number;
    } | null>;
    authenticatorsByType: Array<{
      __typename?: 'AuthenticatorByType';
      _id: AuthResourceType;
      count: number;
    } | null>;
  };
};

export type GetServiceInstancesQueryVariables = Exact<{ [key: string]: never }>;

export type GetServiceInstancesQuery = {
  __typename?: 'Query';
  serviceInstances: any;
};

export type GetServicesQueryVariables = Exact<{
  identityId?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  integrationId?: InputMaybe<Array<IntegrationId>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  isIntegration?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetServicesQuery = {
  __typename?: 'Query';
  services?: Array<{
    __typename?: 'Service';
    _id: any;
    displayName: string;
    instanceName?: string | null;
    status: ServiceStatus;
    serviceIdentityCount: number;
    isDefaultITSMService?: boolean | null;
    integration?:
      | {
          __typename?: 'AWSIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'ActiveDirectoryIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'EntraIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'GithubIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'GoogleWorkspaceIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'JiraIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'OktaIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'SalesforceIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'ServiceNowIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | {
          __typename?: 'SnowflakeIntegration';
          integrationId: IntegrationId;
          description?: string | null;
          activeAuthType: IntegrationAuthTypes;
          authFields: any;
          projectKey?: string | null;
        }
      | null;
  }> | null;
};

export type GetUnassignedIdentitiesCountQueryVariables = Exact<{
  unassignedField: UnassignedFieldEnum;
}>;

export type GetUnassignedIdentitiesCountQuery = {
  __typename?: 'Query';
  unassignedIdentitiesCount: number;
};

export type GetUsersQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<GetUsersSort>;
  status?: InputMaybe<Array<InputMaybe<UserStatus>>>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'User';
    _id: any;
    displayName?: string | null;
    email: string;
    status: UserStatus;
    role: UserRole;
    org: { __typename?: 'Org'; _id: any };
  }> | null;
};

export const OrgRegistrationFieldsFragmentDoc = gql`
  fragment OrgRegistrationFields on Org {
    _id
    displayName
    slug
    status
  }
`;
export const UserRegistrationFieldsFragmentDoc = gql`
  fragment UserRegistrationFields on User {
    _id
    org {
      _id
    }
    email
    displayName
    status
    descope {
      userId
    }
  }
`;
export const CurrentOrgFieldsFragmentDoc = gql`
  fragment CurrentOrgFields on Org {
    _id
    slug
    displayName
    status
    onboarding {
      connectedIDP
    }
    defaultITSMService {
      _id
      integration {
        integrationId
        projectKey
      }
      instanceName
    }
    featureSwitchOverrides
  }
`;
export const CurrentUserFieldsFragmentDoc = gql`
  fragment CurrentUserFields on User {
    _id
    email
    displayName
    status
  }
`;
export const IngestJobFieldsFragmentDoc = gql`
  fragment IngestJobFields on Job {
    _id
    completedAt
    queuedAt
    startedAt
    status
    triggeredBy {
      _id
    }
    type
    org {
      _id
    }
    context {
      type
      ref
      service {
        _id
        displayName
        integration {
          integrationId
          description
        }
      }
    }
    processingMetadata {
      processedIdentities
      expectedIdentities
    }
    error
    missingScopes
  }
`;
export const IdentityFieldFragmentDoc = gql`
  fragment IdentityField on Identity {
    _id
    displayName
    email
    owner {
      _id
      displayName
      email
      status
    }
    classification {
      accountType
      scope
    }
    serviceIdentities {
      _id
      service {
        displayName
        integration {
          integrationId
        }
        inferred {
          integrationId
          integrationLookupId
        }
        auxiliary {
          integrationId
        }
      }
    }
  }
`;
export const IdentityFieldForListFragmentDoc = gql`
  fragment IdentityFieldForList on Identity {
    _id
    displayName
    owner {
      _id
      displayName
      email
      status
    }
  }
`;
export const PermissionSetDetailFieldFragmentDoc = gql`
  fragment PermissionSetDetailField on PermissionSetDetails {
    permissions {
      effect
      action
      filter
    }
  }
`;
export const GroupDetailFieldFragmentDoc = gql`
  fragment GroupDetailField on GroupDetails {
    authResources
  }
`;
export const AuthenticatorDetailsFieldFragmentDoc = gql`
  fragment AuthenticatorDetailsField on AuthenticatorDetails {
    serviceIdentityRef
    usageDetails {
      name
      lastUsed
      service
      integrationId
    }
    scopes
  }
`;
export const RoleDetailFieldFragmentDoc = gql`
  fragment RoleDetailField on RoleDetails {
    authResources
    externalType
    lastProgrammaticActivity
    lastInteractiveActivity
    trustedEntities {
      entity
      entityType
      lastInteractiveUsage
      lastProgrammaticUsage
    }
  }
`;
export const AlertDetailFieldsFragmentDoc = gql`
  fragment AlertDetailFields on Alert {
    _id
    org {
      _id
      slug
      defaultITSMService {
        _id
        integration {
          integrationId
          projectKey
        }
      }
    }
    issueId
    type
    severity
    status
    detectedOn
    assignee {
      _id
      email
      displayName
    }
    target {
      type
    }
    identity {
      _id
      displayName
      email
      owner {
        email
        displayName
      }
    }
    serviceIdentity {
      _id
      principal {
        principal
      }
      authResources {
        _id
        name
        lastActivity
        details {
          type
        }
      }
      externalId
      displayName
      username
      granularType
      attributes
      lastActivity
      service {
        _id
        instanceName
        integration {
          integrationId
        }
      }
      metadata {
        createdAt
        lastProgrammaticActivity
        lastInteractiveActivity
      }
      owner {
        email
        displayName
      }
    }
    authResource {
      _id
      name
      externalId
      createdOn
      lastActivity
      details {
        type
        ... on AuthenticatorDetails {
          usageDetails {
            service
            integrationId
          }
        }
      }
    }
    service {
      _id
      integration {
        integrationId
      }
      instanceName
      displayName
    }
    itsmTicket {
      externalTicketURL
      externalTicketId
      integrationId
      status
    }
  }
`;
export const ChangeUserRoleDocument = gql`
  mutation changeUserRole($userId: ObjectID!, $role: UserRole!) {
    changeUserRole(input: { userId: $userId, newRole: $role }) {
      _id
      org {
        _id
      }
      displayName
      email
      status
      role
    }
  }
`;
export type ChangeUserRoleMutationFn = Apollo.MutationFunction<
  ChangeUserRoleMutation,
  ChangeUserRoleMutationVariables
>;

/**
 * __useChangeUserRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserRoleMutation, { data, loading, error }] = useChangeUserRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserRoleMutation,
    ChangeUserRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserRoleMutation,
    ChangeUserRoleMutationVariables
  >(ChangeUserRoleDocument, options);
}
export type ChangeUserRoleMutationHookResult = ReturnType<
  typeof useChangeUserRoleMutation
>;
export type ChangeUserRoleMutationResult =
  Apollo.MutationResult<ChangeUserRoleMutation>;
export type ChangeUserRoleMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserRoleMutation,
  ChangeUserRoleMutationVariables
>;
export const CreateClassificationRulesetDocument = gql`
  mutation createClassificationRuleset(
    $body: ClassificationRulesetCreateBody!
  ) {
    createClassificationRuleset(body: $body) {
      _id
      rules {
        _id
        description
        type
        category
        property
        rule
        targetType
        scope
      }
    }
  }
`;
export type CreateClassificationRulesetMutationFn = Apollo.MutationFunction<
  CreateClassificationRulesetMutation,
  CreateClassificationRulesetMutationVariables
>;

/**
 * __useCreateClassificationRulesetMutation__
 *
 * To run a mutation, you first call `useCreateClassificationRulesetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassificationRulesetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassificationRulesetMutation, { data, loading, error }] = useCreateClassificationRulesetMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateClassificationRulesetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >(CreateClassificationRulesetDocument, options);
}
export type CreateClassificationRulesetMutationHookResult = ReturnType<
  typeof useCreateClassificationRulesetMutation
>;
export type CreateClassificationRulesetMutationResult =
  Apollo.MutationResult<CreateClassificationRulesetMutation>;
export type CreateClassificationRulesetMutationOptions =
  Apollo.BaseMutationOptions<
    CreateClassificationRulesetMutation,
    CreateClassificationRulesetMutationVariables
  >;
export const CreateItsmTicketFromAlertDocument = gql`
  mutation createITSMTicketFromAlert($input: CreateITSMTicketInput!) {
    createITSMTicketFromAlert(input: $input) {
      status
      createdAt
      externalTicketId
      externalTicketURL
      lastSyncedAt
    }
  }
`;
export type CreateItsmTicketFromAlertMutationFn = Apollo.MutationFunction<
  CreateItsmTicketFromAlertMutation,
  CreateItsmTicketFromAlertMutationVariables
>;

/**
 * __useCreateItsmTicketFromAlertMutation__
 *
 * To run a mutation, you first call `useCreateItsmTicketFromAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItsmTicketFromAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItsmTicketFromAlertMutation, { data, loading, error }] = useCreateItsmTicketFromAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItsmTicketFromAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateItsmTicketFromAlertMutation,
    CreateItsmTicketFromAlertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateItsmTicketFromAlertMutation,
    CreateItsmTicketFromAlertMutationVariables
  >(CreateItsmTicketFromAlertDocument, options);
}
export type CreateItsmTicketFromAlertMutationHookResult = ReturnType<
  typeof useCreateItsmTicketFromAlertMutation
>;
export type CreateItsmTicketFromAlertMutationResult =
  Apollo.MutationResult<CreateItsmTicketFromAlertMutation>;
export type CreateItsmTicketFromAlertMutationOptions =
  Apollo.BaseMutationOptions<
    CreateItsmTicketFromAlertMutation,
    CreateItsmTicketFromAlertMutationVariables
  >;
export const CreateIngestJobDocument = gql`
  mutation createIngestJob($input: CreateIngestJobInput!) {
    createIngestJob(input: $input) {
      _id
      completedAt
      queuedAt
      startedAt
      status
      triggeredBy {
        _id
      }
      type
    }
  }
`;
export type CreateIngestJobMutationFn = Apollo.MutationFunction<
  CreateIngestJobMutation,
  CreateIngestJobMutationVariables
>;

/**
 * __useCreateIngestJobMutation__
 *
 * To run a mutation, you first call `useCreateIngestJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngestJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngestJobMutation, { data, loading, error }] = useCreateIngestJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIngestJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIngestJobMutation,
    CreateIngestJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIngestJobMutation,
    CreateIngestJobMutationVariables
  >(CreateIngestJobDocument, options);
}
export type CreateIngestJobMutationHookResult = ReturnType<
  typeof useCreateIngestJobMutation
>;
export type CreateIngestJobMutationResult =
  Apollo.MutationResult<CreateIngestJobMutation>;
export type CreateIngestJobMutationOptions = Apollo.BaseMutationOptions<
  CreateIngestJobMutation,
  CreateIngestJobMutationVariables
>;
export const CreateJobChainDocument = gql`
  mutation createJobChain($input: CreateJobChainInput!) {
    createJobChain(input: $input) {
      _id
      completedAt
      queuedAt
      startedAt
      status
      triggeredBy {
        _id
      }
      type
    }
  }
`;
export type CreateJobChainMutationFn = Apollo.MutationFunction<
  CreateJobChainMutation,
  CreateJobChainMutationVariables
>;

/**
 * __useCreateJobChainMutation__
 *
 * To run a mutation, you first call `useCreateJobChainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobChainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobChainMutation, { data, loading, error }] = useCreateJobChainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobChainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateJobChainMutation,
    CreateJobChainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateJobChainMutation,
    CreateJobChainMutationVariables
  >(CreateJobChainDocument, options);
}
export type CreateJobChainMutationHookResult = ReturnType<
  typeof useCreateJobChainMutation
>;
export type CreateJobChainMutationResult =
  Apollo.MutationResult<CreateJobChainMutation>;
export type CreateJobChainMutationOptions = Apollo.BaseMutationOptions<
  CreateJobChainMutation,
  CreateJobChainMutationVariables
>;
export const CreateServiceFromIntegrationDocument = gql`
  mutation createServiceFromIntegration($input: CreateIntegrationInput!) {
    createServiceFromIntegration(input: $input) {
      _id
      status
      integration {
        __typename
        status
        ... on OktaIntegration {
          domain
        }
        ... on GoogleWorkspaceIntegration {
          scope
        }
      }
    }
  }
`;
export type CreateServiceFromIntegrationMutationFn = Apollo.MutationFunction<
  CreateServiceFromIntegrationMutation,
  CreateServiceFromIntegrationMutationVariables
>;

/**
 * __useCreateServiceFromIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateServiceFromIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceFromIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceFromIntegrationMutation, { data, loading, error }] = useCreateServiceFromIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceFromIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >(CreateServiceFromIntegrationDocument, options);
}
export type CreateServiceFromIntegrationMutationHookResult = ReturnType<
  typeof useCreateServiceFromIntegrationMutation
>;
export type CreateServiceFromIntegrationMutationResult =
  Apollo.MutationResult<CreateServiceFromIntegrationMutation>;
export type CreateServiceFromIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateServiceFromIntegrationMutation,
    CreateServiceFromIntegrationMutationVariables
  >;
export const CreateUserFromIdentityDocument = gql`
  mutation createUserFromIdentity($input: CreateUserFromIdentityInput!) {
    createUserFromIdentity(input: $input) {
      _id
      email
    }
  }
`;
export type CreateUserFromIdentityMutationFn = Apollo.MutationFunction<
  CreateUserFromIdentityMutation,
  CreateUserFromIdentityMutationVariables
>;

/**
 * __useCreateUserFromIdentityMutation__
 *
 * To run a mutation, you first call `useCreateUserFromIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromIdentityMutation, { data, loading, error }] = useCreateUserFromIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserFromIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFromIdentityMutation,
    CreateUserFromIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserFromIdentityMutation,
    CreateUserFromIdentityMutationVariables
  >(CreateUserFromIdentityDocument, options);
}
export type CreateUserFromIdentityMutationHookResult = ReturnType<
  typeof useCreateUserFromIdentityMutation
>;
export type CreateUserFromIdentityMutationResult =
  Apollo.MutationResult<CreateUserFromIdentityMutation>;
export type CreateUserFromIdentityMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFromIdentityMutation,
  CreateUserFromIdentityMutationVariables
>;
export const DeleteServiceIntegrationDocument = gql`
  mutation deleteServiceIntegration($_id: ObjectID!) {
    deleteServiceIntegration(_id: $_id) {
      _id
      type
    }
  }
`;
export type DeleteServiceIntegrationMutationFn = Apollo.MutationFunction<
  DeleteServiceIntegrationMutation,
  DeleteServiceIntegrationMutationVariables
>;

/**
 * __useDeleteServiceIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteServiceIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceIntegrationMutation, { data, loading, error }] = useDeleteServiceIntegrationMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteServiceIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >(DeleteServiceIntegrationDocument, options);
}
export type DeleteServiceIntegrationMutationHookResult = ReturnType<
  typeof useDeleteServiceIntegrationMutation
>;
export type DeleteServiceIntegrationMutationResult =
  Apollo.MutationResult<DeleteServiceIntegrationMutation>;
export type DeleteServiceIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteServiceIntegrationMutation,
    DeleteServiceIntegrationMutationVariables
  >;
export const DeleteUserDocument = gql`
  mutation deleteUser($_id: ObjectID!) {
    deleteUser(_id: $_id) {
      _id
      org {
        _id
      }
      displayName
      email
      status
      role
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation inviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      _id
      email
      displayName
      status
      descope {
        userId
      }
      role
      org {
        _id
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserMutation,
    InviteUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<
  typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
  Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const RegisterDocument = gql`
  mutation register(
    $displayName: String
    $email: String
    $orgDisplayName: String
    $orgSlug: String
  ) {
    register(
      displayName: $displayName
      email: $email
      orgDisplayName: $orgDisplayName
      orgSlug: $orgSlug
    ) {
      currentOrg {
        ...OrgRegistrationFields
      }
      currentUser {
        ...UserRegistrationFields
      }
    }
  }
  ${OrgRegistrationFieldsFragmentDoc}
  ${UserRegistrationFieldsFragmentDoc}
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      email: // value for 'email'
 *      orgDisplayName: // value for 'orgDisplayName'
 *      orgSlug: // value for 'orgSlug'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const UpdateAlertDocument = gql`
  mutation updateAlert($input: AlertUpdateBody!) {
    updateAlert(input: $input) {
      _id
    }
  }
`;
export type UpdateAlertMutationFn = Apollo.MutationFunction<
  UpdateAlertMutation,
  UpdateAlertMutationVariables
>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlertMutation,
    UpdateAlertMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(
    UpdateAlertDocument,
    options
  );
}
export type UpdateAlertMutationHookResult = ReturnType<
  typeof useUpdateAlertMutation
>;
export type UpdateAlertMutationResult =
  Apollo.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<
  UpdateAlertMutation,
  UpdateAlertMutationVariables
>;
export const UpdateAlertsDocument = gql`
  mutation updateAlerts($input: AlertsUpdateBody!) {
    updateAlerts(input: $input) {
      modifiedCount
    }
  }
`;
export type UpdateAlertsMutationFn = Apollo.MutationFunction<
  UpdateAlertsMutation,
  UpdateAlertsMutationVariables
>;

/**
 * __useUpdateAlertsMutation__
 *
 * To run a mutation, you first call `useUpdateAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertsMutation, { data, loading, error }] = useUpdateAlertsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlertsMutation,
    UpdateAlertsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAlertsMutation,
    UpdateAlertsMutationVariables
  >(UpdateAlertsDocument, options);
}
export type UpdateAlertsMutationHookResult = ReturnType<
  typeof useUpdateAlertsMutation
>;
export type UpdateAlertsMutationResult =
  Apollo.MutationResult<UpdateAlertsMutation>;
export type UpdateAlertsMutationOptions = Apollo.BaseMutationOptions<
  UpdateAlertsMutation,
  UpdateAlertsMutationVariables
>;
export const UpdateItsmServiceDocument = gql`
  mutation updateITSMService($input: ITSMServiceUpdatePatchInput!) {
    updateITSMService(input: $input) {
      _id
      integration {
        integrationId
        authFields
      }
    }
  }
`;
export type UpdateItsmServiceMutationFn = Apollo.MutationFunction<
  UpdateItsmServiceMutation,
  UpdateItsmServiceMutationVariables
>;

/**
 * __useUpdateItsmServiceMutation__
 *
 * To run a mutation, you first call `useUpdateItsmServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItsmServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItsmServiceMutation, { data, loading, error }] = useUpdateItsmServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItsmServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItsmServiceMutation,
    UpdateItsmServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateItsmServiceMutation,
    UpdateItsmServiceMutationVariables
  >(UpdateItsmServiceDocument, options);
}
export type UpdateItsmServiceMutationHookResult = ReturnType<
  typeof useUpdateItsmServiceMutation
>;
export type UpdateItsmServiceMutationResult =
  Apollo.MutationResult<UpdateItsmServiceMutation>;
export type UpdateItsmServiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateItsmServiceMutation,
  UpdateItsmServiceMutationVariables
>;
export const UpdateIdentitiesDocument = gql`
  mutation updateIdentities($input: BulkUpdatesIdentitiesInput!) {
    updateIdentities(input: $input) {
      _id
      email
      displayName
      owner {
        _id
        displayName
        email
      }
    }
  }
`;
export type UpdateIdentitiesMutationFn = Apollo.MutationFunction<
  UpdateIdentitiesMutation,
  UpdateIdentitiesMutationVariables
>;

/**
 * __useUpdateIdentitiesMutation__
 *
 * To run a mutation, you first call `useUpdateIdentitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentitiesMutation, { data, loading, error }] = useUpdateIdentitiesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIdentitiesMutation,
    UpdateIdentitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIdentitiesMutation,
    UpdateIdentitiesMutationVariables
  >(UpdateIdentitiesDocument, options);
}
export type UpdateIdentitiesMutationHookResult = ReturnType<
  typeof useUpdateIdentitiesMutation
>;
export type UpdateIdentitiesMutationResult =
  Apollo.MutationResult<UpdateIdentitiesMutation>;
export type UpdateIdentitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateIdentitiesMutation,
  UpdateIdentitiesMutationVariables
>;
export const UpdateIdentityDocument = gql`
  mutation updateIdentity($input: IdentityUpdateBody!) {
    updateIdentity(input: $input) {
      _id
      email
      displayName
      org {
        _id
        displayName
        slug
      }
      owner {
        _id
        displayName
        email
        org {
          _id
          displayName
          slug
        }
      }
      classification {
        accountType
      }
    }
  }
`;
export type UpdateIdentityMutationFn = Apollo.MutationFunction<
  UpdateIdentityMutation,
  UpdateIdentityMutationVariables
>;

/**
 * __useUpdateIdentityMutation__
 *
 * To run a mutation, you first call `useUpdateIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentityMutation, { data, loading, error }] = useUpdateIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIdentityMutation,
    UpdateIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateIdentityMutation,
    UpdateIdentityMutationVariables
  >(UpdateIdentityDocument, options);
}
export type UpdateIdentityMutationHookResult = ReturnType<
  typeof useUpdateIdentityMutation
>;
export type UpdateIdentityMutationResult =
  Apollo.MutationResult<UpdateIdentityMutation>;
export type UpdateIdentityMutationOptions = Apollo.BaseMutationOptions<
  UpdateIdentityMutation,
  UpdateIdentityMutationVariables
>;
export const UpdateOrgDocument = gql`
  mutation updateOrg($input: OrgUpdateBody!) {
    updateOrg(input: $input) {
      _id
      displayName
      status
      onboarding {
        connectedIDP
      }
    }
  }
`;
export type UpdateOrgMutationFn = Apollo.MutationFunction<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrgMutation,
    UpdateOrgMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
    UpdateOrgDocument,
    options
  );
}
export type UpdateOrgMutationHookResult = ReturnType<
  typeof useUpdateOrgMutation
>;
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>;
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>;
export const UpdateServiceIdentityDocument = gql`
  mutation updateServiceIdentity($input: ServiceIdentityUpdateBody!) {
    updateServiceIdentity(input: $input) {
      _id
      displayName
      org {
        _id
        displayName
        slug
      }
      owner {
        _id
        displayName
        email
      }
    }
  }
`;
export type UpdateServiceIdentityMutationFn = Apollo.MutationFunction<
  UpdateServiceIdentityMutation,
  UpdateServiceIdentityMutationVariables
>;

/**
 * __useUpdateServiceIdentityMutation__
 *
 * To run a mutation, you first call `useUpdateServiceIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceIdentityMutation, { data, loading, error }] = useUpdateServiceIdentityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceIdentityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceIdentityMutation,
    UpdateServiceIdentityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceIdentityMutation,
    UpdateServiceIdentityMutationVariables
  >(UpdateServiceIdentityDocument, options);
}
export type UpdateServiceIdentityMutationHookResult = ReturnType<
  typeof useUpdateServiceIdentityMutation
>;
export type UpdateServiceIdentityMutationResult =
  Apollo.MutationResult<UpdateServiceIdentityMutation>;
export type UpdateServiceIdentityMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceIdentityMutation,
  UpdateServiceIdentityMutationVariables
>;
export const GetActivitiesDocument = gql`
  query getActivities(
    $identityId: [ObjectID!]!
    $serviceId: [ObjectID!]!
    $offset: Int!
    $limit: Int!
    $sort: GetActivitiesSort
  ) {
    activities(
      identityId: $identityId
      serviceId: $serviceId
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      _id
      actorType
      actorName
      actionType
      org {
        _id
      }
      service {
        _id
      }
      identities {
        _id
      }
      timestamp
    }
    activitiesCount(identityId: $identityId, serviceId: $serviceId) {
      count
    }
  }
`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *      serviceId: // value for 'serviceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  > &
    (
      | { variables: GetActivitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(
    GetActivitiesDocument,
    options
  );
}
export function useGetActivitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivitiesQuery,
    GetActivitiesQueryVariables
  >(GetActivitiesDocument, options);
}
export type GetActivitiesQueryHookResult = ReturnType<
  typeof useGetActivitiesQuery
>;
export type GetActivitiesLazyQueryHookResult = ReturnType<
  typeof useGetActivitiesLazyQuery
>;
export type GetActivitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetActivitiesSuspenseQuery
>;
export type GetActivitiesQueryResult = Apollo.QueryResult<
  GetActivitiesQuery,
  GetActivitiesQueryVariables
>;
export const GetActivityDetailsDocument = gql`
  query getActivityDetails($_id: ObjectID!) {
    activityDetails(_id: $_id) {
      _id
      org {
        _id
      }
      detail {
        integrationId
        activityId
        ... on OktaActivityDetail {
          oktaUserId
          oktaUserDisplayName
          displayMessage
          eventType
          severity
        }
      }
    }
  }
`;

/**
 * __useGetActivityDetailsQuery__
 *
 * To run a query within a React component, call `useGetActivityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetActivityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  > &
    (
      | { variables: GetActivityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export function useGetActivityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export function useGetActivityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivityDetailsQuery,
    GetActivityDetailsQueryVariables
  >(GetActivityDetailsDocument, options);
}
export type GetActivityDetailsQueryHookResult = ReturnType<
  typeof useGetActivityDetailsQuery
>;
export type GetActivityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetActivityDetailsLazyQuery
>;
export type GetActivityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityDetailsSuspenseQuery
>;
export type GetActivityDetailsQueryResult = Apollo.QueryResult<
  GetActivityDetailsQuery,
  GetActivityDetailsQueryVariables
>;
export const GetAlertCountsDocument = gql`
  query getAlertCounts(
    $accounts: [ObjectID!]
    $assignees: [ObjectID!]
    $identities: [ObjectID!]
    $integrationIds: [IntegrationId!]
    $severities: [AlertSeverity!]
    $statuses: [AlertStatus!]
    $groupBy: AlertGroupBy
    $limit: Int
  ) {
    alertCounts(
      accounts: $accounts
      assignees: $assignees
      identities: $identities
      integrationIds: $integrationIds
      severities: $severities
      statuses: $statuses
      groupBy: $groupBy
      limit: $limit
    ) {
      group
      groupId
      count
    }
  }
`;

/**
 * __useGetAlertCountsQuery__
 *
 * To run a query within a React component, call `useGetAlertCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertCountsQuery({
 *   variables: {
 *      accounts: // value for 'accounts'
 *      assignees: // value for 'assignees'
 *      identities: // value for 'identities'
 *      integrationIds: // value for 'integrationIds'
 *      severities: // value for 'severities'
 *      statuses: // value for 'statuses'
 *      groupBy: // value for 'groupBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAlertCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAlertCountsQuery,
    GetAlertCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlertCountsQuery, GetAlertCountsQueryVariables>(
    GetAlertCountsDocument,
    options
  );
}
export function useGetAlertCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertCountsQuery,
    GetAlertCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlertCountsQuery, GetAlertCountsQueryVariables>(
    GetAlertCountsDocument,
    options
  );
}
export function useGetAlertCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAlertCountsQuery,
    GetAlertCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAlertCountsQuery,
    GetAlertCountsQueryVariables
  >(GetAlertCountsDocument, options);
}
export type GetAlertCountsQueryHookResult = ReturnType<
  typeof useGetAlertCountsQuery
>;
export type GetAlertCountsLazyQueryHookResult = ReturnType<
  typeof useGetAlertCountsLazyQuery
>;
export type GetAlertCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetAlertCountsSuspenseQuery
>;
export type GetAlertCountsQueryResult = Apollo.QueryResult<
  GetAlertCountsQuery,
  GetAlertCountsQueryVariables
>;
export const GetAlertDetailsDocument = gql`
  query getAlertDetails($_id: ObjectID!) {
    getAlertDetails(_id: $_id) {
      ...AlertDetailFields
      identity {
        _id
        displayName
        owner {
          displayName
          email
        }
        classification {
          accountType
        }
        serviceIdentities {
          _id
          service {
            displayName
            integration {
              integrationId
            }
            inferred {
              integrationLookupId
              integrationId
            }
          }
        }
      }
      itsmTicket {
        status
        externalTicketId
        externalTicketURL
        lastSyncedAt
        createdAt
      }
    }
  }
  ${AlertDetailFieldsFragmentDoc}
`;

/**
 * __useGetAlertDetailsQuery__
 *
 * To run a query within a React component, call `useGetAlertDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetAlertDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAlertDetailsQuery,
    GetAlertDetailsQueryVariables
  > &
    (
      | { variables: GetAlertDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlertDetailsQuery, GetAlertDetailsQueryVariables>(
    GetAlertDetailsDocument,
    options
  );
}
export function useGetAlertDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertDetailsQuery,
    GetAlertDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAlertDetailsQuery,
    GetAlertDetailsQueryVariables
  >(GetAlertDetailsDocument, options);
}
export function useGetAlertDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAlertDetailsQuery,
    GetAlertDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAlertDetailsQuery,
    GetAlertDetailsQueryVariables
  >(GetAlertDetailsDocument, options);
}
export type GetAlertDetailsQueryHookResult = ReturnType<
  typeof useGetAlertDetailsQuery
>;
export type GetAlertDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAlertDetailsLazyQuery
>;
export type GetAlertDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetAlertDetailsSuspenseQuery
>;
export type GetAlertDetailsQueryResult = Apollo.QueryResult<
  GetAlertDetailsQuery,
  GetAlertDetailsQueryVariables
>;
export const GetAlertsDocument = gql`
  query getAlerts(
    $severities: [AlertSeverity!]
    $assignees: [ObjectID!]
    $integrationIds: [IntegrationId!]
    $accounts: [ObjectID!]
    $identities: [ObjectID!]
    $authResources: [ObjectID!]
    $statuses: [AlertStatus!]
    $targetTypes: [AlertTargetType!]
    $q: String
    $offset: Int
    $limit: Int
    $sort: GetAlertsSort
  ) {
    alerts(
      severities: $severities
      assignees: $assignees
      integrationIds: $integrationIds
      accounts: $accounts
      identities: $identities
      authResources: $authResources
      statuses: $statuses
      targetTypes: $targetTypes
      q: $q
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      ...AlertDetailFields
    }
  }
  ${AlertDetailFieldsFragmentDoc}
`;

/**
 * __useGetAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertsQuery({
 *   variables: {
 *      severities: // value for 'severities'
 *      assignees: // value for 'assignees'
 *      integrationIds: // value for 'integrationIds'
 *      accounts: // value for 'accounts'
 *      identities: // value for 'identities'
 *      authResources: // value for 'authResources'
 *      statuses: // value for 'statuses'
 *      targetTypes: // value for 'targetTypes'
 *      q: // value for 'q'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAlertsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAlertsQuery, GetAlertsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options
  );
}
export function useGetAlertsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options
  );
}
export function useGetAlertsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GetAlertsDocument,
    options
  );
}
export type GetAlertsQueryHookResult = ReturnType<typeof useGetAlertsQuery>;
export type GetAlertsLazyQueryHookResult = ReturnType<
  typeof useGetAlertsLazyQuery
>;
export type GetAlertsSuspenseQueryHookResult = ReturnType<
  typeof useGetAlertsSuspenseQuery
>;
export type GetAlertsQueryResult = Apollo.QueryResult<
  GetAlertsQuery,
  GetAlertsQueryVariables
>;
export const AlertsGroupedByDocument = gql`
  query AlertsGroupedBy(
    $severities: [AlertSeverity!]
    $assignees: [ObjectID!]
    $integrationIds: [IntegrationId!]
    $accounts: [ObjectID!]
    $identities: [ObjectID!]
    $statuses: [AlertStatus!]
    $groupBy: AlertGroupBy
    $q: String
    $offset: Int
    $limit: Int
    $sort: GetAlertsSort
  ) {
    alertsGroupedBy(
      severities: $severities
      assignees: $assignees
      integrationIds: $integrationIds
      accounts: $accounts
      identities: $identities
      statuses: $statuses
      groupBy: $groupBy
      q: $q
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      groupBy
      groupValue {
        name
        integrationId
        identity
      }
      alerts {
        ...AlertDetailFields
      }
      total
      severities {
        severity
        count
      }
      integrationIds
      assignees
      assigneeObjects {
        _id
        displayName
        email
      }
      identity {
        _id
        displayName
        email
      }
    }
  }
  ${AlertDetailFieldsFragmentDoc}
`;

/**
 * __useAlertsGroupedByQuery__
 *
 * To run a query within a React component, call `useAlertsGroupedByQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertsGroupedByQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertsGroupedByQuery({
 *   variables: {
 *      severities: // value for 'severities'
 *      assignees: // value for 'assignees'
 *      integrationIds: // value for 'integrationIds'
 *      accounts: // value for 'accounts'
 *      identities: // value for 'identities'
 *      statuses: // value for 'statuses'
 *      groupBy: // value for 'groupBy'
 *      q: // value for 'q'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAlertsGroupedByQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AlertsGroupedByQuery,
    AlertsGroupedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AlertsGroupedByQuery, AlertsGroupedByQueryVariables>(
    AlertsGroupedByDocument,
    options
  );
}
export function useAlertsGroupedByLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AlertsGroupedByQuery,
    AlertsGroupedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AlertsGroupedByQuery,
    AlertsGroupedByQueryVariables
  >(AlertsGroupedByDocument, options);
}
export function useAlertsGroupedBySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AlertsGroupedByQuery,
    AlertsGroupedByQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AlertsGroupedByQuery,
    AlertsGroupedByQueryVariables
  >(AlertsGroupedByDocument, options);
}
export type AlertsGroupedByQueryHookResult = ReturnType<
  typeof useAlertsGroupedByQuery
>;
export type AlertsGroupedByLazyQueryHookResult = ReturnType<
  typeof useAlertsGroupedByLazyQuery
>;
export type AlertsGroupedBySuspenseQueryHookResult = ReturnType<
  typeof useAlertsGroupedBySuspenseQuery
>;
export type AlertsGroupedByQueryResult = Apollo.QueryResult<
  AlertsGroupedByQuery,
  AlertsGroupedByQueryVariables
>;
export const GetAuditLogsDocument = gql`
  query getAuditLogs(
    $eventType: AuditEvent
    $actorType: AuditActorType
    $fromDate: String
    $toDate: String
    $sort: AuditLogSort
    $offset: Int
    $limit: Int
  ) {
    auditLogs(
      eventType: $eventType
      actorType: $actorType
      fromDate: $fromDate
      toDate: $toDate
      sort: $sort
      offset: $offset
      limit: $limit
    ) {
      _id
      timestamp
      event
      actor {
        type
        details {
          email
          displayName
        }
      }
      targets {
        type
        details {
          ... on User {
            email
            displayName
          }
          ... on Alert {
            issueId
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditLogsQuery({
 *   variables: {
 *      eventType: // value for 'eventType'
 *      actorType: // value for 'actorType'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      sort: // value for 'sort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAuditLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuditLogsQuery,
    GetAuditLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(
    GetAuditLogsDocument,
    options
  );
}
export function useGetAuditLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuditLogsQuery,
    GetAuditLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(
    GetAuditLogsDocument,
    options
  );
}
export function useGetAuditLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuditLogsQuery,
    GetAuditLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAuditLogsQuery, GetAuditLogsQueryVariables>(
    GetAuditLogsDocument,
    options
  );
}
export type GetAuditLogsQueryHookResult = ReturnType<
  typeof useGetAuditLogsQuery
>;
export type GetAuditLogsLazyQueryHookResult = ReturnType<
  typeof useGetAuditLogsLazyQuery
>;
export type GetAuditLogsSuspenseQueryHookResult = ReturnType<
  typeof useGetAuditLogsSuspenseQuery
>;
export type GetAuditLogsQueryResult = Apollo.QueryResult<
  GetAuditLogsQuery,
  GetAuditLogsQueryVariables
>;
export const GetAuthResourceDetailsDocument = gql`
  query getAuthResourceDetails($_id: ObjectID!) {
    authResourceDetails(_id: $_id) {
      _id
      service {
        _id
        displayName
        integration {
          integrationId
        }
        instanceName
      }
      status
      externalId
      integrationId
      name
      createdOn
      lastActivity
      details {
        type
        ...AuthenticatorDetailsField
        ...RoleDetailField
        ...GroupDetailField
      }
      serviceIdentities {
        _id
        principal {
          principal
        }
        externalId
        displayName
        granularType
        lastActivity
        owner {
          _id
          status
          displayName
        }
      }
    }
  }
  ${AuthenticatorDetailsFieldFragmentDoc}
  ${RoleDetailFieldFragmentDoc}
  ${GroupDetailFieldFragmentDoc}
`;

/**
 * __useGetAuthResourceDetailsQuery__
 *
 * To run a query within a React component, call `useGetAuthResourceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthResourceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthResourceDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetAuthResourceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  > &
    (
      | { variables: GetAuthResourceDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export function useGetAuthResourceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export function useGetAuthResourceDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAuthResourceDetailsQuery,
    GetAuthResourceDetailsQueryVariables
  >(GetAuthResourceDetailsDocument, options);
}
export type GetAuthResourceDetailsQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsQuery
>;
export type GetAuthResourceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsLazyQuery
>;
export type GetAuthResourceDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetAuthResourceDetailsSuspenseQuery
>;
export type GetAuthResourceDetailsQueryResult = Apollo.QueryResult<
  GetAuthResourceDetailsQuery,
  GetAuthResourceDetailsQueryVariables
>;
export const GetAuthResourcesDocument = gql`
  query getAuthResources(
    $authResourceType: [AuthResourceType!]
    $serviceId: [ObjectID!]
    $status: [AuthResourceStatus!]
    $integrationId: [IntegrationId!]
    $externalId: String
    $offset: Int!
    $limit: Int!
    $q: String
    $onlyEntitlements: Boolean
  ) {
    authResources(
      authResourceType: $authResourceType
      serviceId: $serviceId
      status: $status
      integrationId: $integrationId
      externalId: $externalId
      offset: $offset
      limit: $limit
      q: $q
      onlyEntitlements: $onlyEntitlements
    ) {
      _id
      service {
        _id
        integration {
          integrationId
        }
        instanceName
      }
      status
      externalId
      lastActivity
      name
      integrationId
      details {
        type
        ...AuthenticatorDetailsField
        ...RoleDetailField
        ...GroupDetailField
      }
    }
  }
  ${AuthenticatorDetailsFieldFragmentDoc}
  ${RoleDetailFieldFragmentDoc}
  ${GroupDetailFieldFragmentDoc}
`;

/**
 * __useGetAuthResourcesQuery__
 *
 * To run a query within a React component, call `useGetAuthResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthResourcesQuery({
 *   variables: {
 *      authResourceType: // value for 'authResourceType'
 *      serviceId: // value for 'serviceId'
 *      status: // value for 'status'
 *      integrationId: // value for 'integrationId'
 *      externalId: // value for 'externalId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      onlyEntitlements: // value for 'onlyEntitlements'
 *   },
 * });
 */
export function useGetAuthResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  > &
    (
      | { variables: GetAuthResourcesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthResourcesQuery, GetAuthResourcesQueryVariables>(
    GetAuthResourcesDocument,
    options
  );
}
export function useGetAuthResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >(GetAuthResourcesDocument, options);
}
export function useGetAuthResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAuthResourcesQuery,
    GetAuthResourcesQueryVariables
  >(GetAuthResourcesDocument, options);
}
export type GetAuthResourcesQueryHookResult = ReturnType<
  typeof useGetAuthResourcesQuery
>;
export type GetAuthResourcesLazyQueryHookResult = ReturnType<
  typeof useGetAuthResourcesLazyQuery
>;
export type GetAuthResourcesSuspenseQueryHookResult = ReturnType<
  typeof useGetAuthResourcesSuspenseQuery
>;
export type GetAuthResourcesQueryResult = Apollo.QueryResult<
  GetAuthResourcesQuery,
  GetAuthResourcesQueryVariables
>;
export const GetClassificationRulesetDocument = gql`
  query getClassificationRuleset {
    classificationRuleset {
      _id
      rules {
        _id
        description
        type
        category
        property
        rule
        targetType
        scope
      }
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetClassificationRulesetQuery__
 *
 * To run a query within a React component, call `useGetClassificationRulesetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationRulesetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationRulesetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationRulesetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export function useGetClassificationRulesetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export function useGetClassificationRulesetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClassificationRulesetQuery,
    GetClassificationRulesetQueryVariables
  >(GetClassificationRulesetDocument, options);
}
export type GetClassificationRulesetQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetQuery
>;
export type GetClassificationRulesetLazyQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetLazyQuery
>;
export type GetClassificationRulesetSuspenseQueryHookResult = ReturnType<
  typeof useGetClassificationRulesetSuspenseQuery
>;
export type GetClassificationRulesetQueryResult = Apollo.QueryResult<
  GetClassificationRulesetQuery,
  GetClassificationRulesetQueryVariables
>;
export const GetDashboardAccountsByServiceAndTypeDocument = gql`
  query getDashboardAccountsByServiceAndType {
    getDashboardAccountsByServiceAndType {
      integrationId
      counts
    }
  }
`;

/**
 * __useGetDashboardAccountsByServiceAndTypeQuery__
 *
 * To run a query within a React component, call `useGetDashboardAccountsByServiceAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAccountsByServiceAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAccountsByServiceAndTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAccountsByServiceAndTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >(GetDashboardAccountsByServiceAndTypeDocument, options);
}
export function useGetDashboardAccountsByServiceAndTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >(GetDashboardAccountsByServiceAndTypeDocument, options);
}
export function useGetDashboardAccountsByServiceAndTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >(GetDashboardAccountsByServiceAndTypeDocument, options);
}
export type GetDashboardAccountsByServiceAndTypeQueryHookResult = ReturnType<
  typeof useGetDashboardAccountsByServiceAndTypeQuery
>;
export type GetDashboardAccountsByServiceAndTypeLazyQueryHookResult =
  ReturnType<typeof useGetDashboardAccountsByServiceAndTypeLazyQuery>;
export type GetDashboardAccountsByServiceAndTypeSuspenseQueryHookResult =
  ReturnType<typeof useGetDashboardAccountsByServiceAndTypeSuspenseQuery>;
export type GetDashboardAccountsByServiceAndTypeQueryResult =
  Apollo.QueryResult<
    GetDashboardAccountsByServiceAndTypeQuery,
    GetDashboardAccountsByServiceAndTypeQueryVariables
  >;
export const GetDashboardAlertsByNameAndSeverityDocument = gql`
  query getDashboardAlertsByNameAndSeverity {
    getDashboardAlertsByNameAndSeverity {
      alertName
      counts
      total
    }
  }
`;

/**
 * __useGetDashboardAlertsByNameAndSeverityQuery__
 *
 * To run a query within a React component, call `useGetDashboardAlertsByNameAndSeverityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAlertsByNameAndSeverityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAlertsByNameAndSeverityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAlertsByNameAndSeverityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >(GetDashboardAlertsByNameAndSeverityDocument, options);
}
export function useGetDashboardAlertsByNameAndSeverityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >(GetDashboardAlertsByNameAndSeverityDocument, options);
}
export function useGetDashboardAlertsByNameAndSeveritySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDashboardAlertsByNameAndSeverityQuery,
    GetDashboardAlertsByNameAndSeverityQueryVariables
  >(GetDashboardAlertsByNameAndSeverityDocument, options);
}
export type GetDashboardAlertsByNameAndSeverityQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsByNameAndSeverityQuery
>;
export type GetDashboardAlertsByNameAndSeverityLazyQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsByNameAndSeverityLazyQuery
>;
export type GetDashboardAlertsByNameAndSeveritySuspenseQueryHookResult =
  ReturnType<typeof useGetDashboardAlertsByNameAndSeveritySuspenseQuery>;
export type GetDashboardAlertsByNameAndSeverityQueryResult = Apollo.QueryResult<
  GetDashboardAlertsByNameAndSeverityQuery,
  GetDashboardAlertsByNameAndSeverityQueryVariables
>;
export const GetDashboardAlertsByServiceAndSeverityDocument = gql`
  query getDashboardAlertsByServiceAndSeverity {
    getDashboardAlertsByServiceAndSeverity {
      service {
        _id
        displayName
        instanceName
        integration {
          integrationId
        }
      }
      counts
      total
    }
  }
`;

/**
 * __useGetDashboardAlertsByServiceAndSeverityQuery__
 *
 * To run a query within a React component, call `useGetDashboardAlertsByServiceAndSeverityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAlertsByServiceAndSeverityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAlertsByServiceAndSeverityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAlertsByServiceAndSeverityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >(GetDashboardAlertsByServiceAndSeverityDocument, options);
}
export function useGetDashboardAlertsByServiceAndSeverityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >(GetDashboardAlertsByServiceAndSeverityDocument, options);
}
export function useGetDashboardAlertsByServiceAndSeveritySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >(GetDashboardAlertsByServiceAndSeverityDocument, options);
}
export type GetDashboardAlertsByServiceAndSeverityQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsByServiceAndSeverityQuery
>;
export type GetDashboardAlertsByServiceAndSeverityLazyQueryHookResult =
  ReturnType<typeof useGetDashboardAlertsByServiceAndSeverityLazyQuery>;
export type GetDashboardAlertsByServiceAndSeveritySuspenseQueryHookResult =
  ReturnType<typeof useGetDashboardAlertsByServiceAndSeveritySuspenseQuery>;
export type GetDashboardAlertsByServiceAndSeverityQueryResult =
  Apollo.QueryResult<
    GetDashboardAlertsByServiceAndSeverityQuery,
    GetDashboardAlertsByServiceAndSeverityQueryVariables
  >;
export const GetDashboardAlertsBySeverityDocument = gql`
  query getDashboardAlertsBySeverity {
    getDashboardAlertsBySeverity {
      severity
      count
    }
  }
`;

/**
 * __useGetDashboardAlertsBySeverityQuery__
 *
 * To run a query within a React component, call `useGetDashboardAlertsBySeverityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAlertsBySeverityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAlertsBySeverityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAlertsBySeverityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >(GetDashboardAlertsBySeverityDocument, options);
}
export function useGetDashboardAlertsBySeverityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >(GetDashboardAlertsBySeverityDocument, options);
}
export function useGetDashboardAlertsBySeveritySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDashboardAlertsBySeverityQuery,
    GetDashboardAlertsBySeverityQueryVariables
  >(GetDashboardAlertsBySeverityDocument, options);
}
export type GetDashboardAlertsBySeverityQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsBySeverityQuery
>;
export type GetDashboardAlertsBySeverityLazyQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsBySeverityLazyQuery
>;
export type GetDashboardAlertsBySeveritySuspenseQueryHookResult = ReturnType<
  typeof useGetDashboardAlertsBySeveritySuspenseQuery
>;
export type GetDashboardAlertsBySeverityQueryResult = Apollo.QueryResult<
  GetDashboardAlertsBySeverityQuery,
  GetDashboardAlertsBySeverityQueryVariables
>;
export const GetDashboardAuthenticatorsByServiceAndTypeDocument = gql`
  query getDashboardAuthenticatorsByServiceAndType {
    getDashboardAuthenticatorsByServiceAndType {
      integrationId
      counts
    }
  }
`;

/**
 * __useGetDashboardAuthenticatorsByServiceAndTypeQuery__
 *
 * To run a query within a React component, call `useGetDashboardAuthenticatorsByServiceAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardAuthenticatorsByServiceAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardAuthenticatorsByServiceAndTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardAuthenticatorsByServiceAndTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >(GetDashboardAuthenticatorsByServiceAndTypeDocument, options);
}
export function useGetDashboardAuthenticatorsByServiceAndTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >(GetDashboardAuthenticatorsByServiceAndTypeDocument, options);
}
export function useGetDashboardAuthenticatorsByServiceAndTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >(GetDashboardAuthenticatorsByServiceAndTypeDocument, options);
}
export type GetDashboardAuthenticatorsByServiceAndTypeQueryHookResult =
  ReturnType<typeof useGetDashboardAuthenticatorsByServiceAndTypeQuery>;
export type GetDashboardAuthenticatorsByServiceAndTypeLazyQueryHookResult =
  ReturnType<typeof useGetDashboardAuthenticatorsByServiceAndTypeLazyQuery>;
export type GetDashboardAuthenticatorsByServiceAndTypeSuspenseQueryHookResult =
  ReturnType<typeof useGetDashboardAuthenticatorsByServiceAndTypeSuspenseQuery>;
export type GetDashboardAuthenticatorsByServiceAndTypeQueryResult =
  Apollo.QueryResult<
    GetDashboardAuthenticatorsByServiceAndTypeQuery,
    GetDashboardAuthenticatorsByServiceAndTypeQueryVariables
  >;
export const GetItsmWorkspacesDocument = gql`
  query getITSMWorkspaces($offset: Int!, $limit: Int!, $q: String) {
    itsmWorkspaces(offset: $offset, limit: $limit, q: $q) {
      projectId
      projectKey
      projectName
    }
  }
`;

/**
 * __useGetItsmWorkspacesQuery__
 *
 * To run a query within a React component, call `useGetItsmWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItsmWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItsmWorkspacesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetItsmWorkspacesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  > &
    (
      | { variables: GetItsmWorkspacesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  >(GetItsmWorkspacesDocument, options);
}
export function useGetItsmWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  >(GetItsmWorkspacesDocument, options);
}
export function useGetItsmWorkspacesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetItsmWorkspacesQuery,
    GetItsmWorkspacesQueryVariables
  >(GetItsmWorkspacesDocument, options);
}
export type GetItsmWorkspacesQueryHookResult = ReturnType<
  typeof useGetItsmWorkspacesQuery
>;
export type GetItsmWorkspacesLazyQueryHookResult = ReturnType<
  typeof useGetItsmWorkspacesLazyQuery
>;
export type GetItsmWorkspacesSuspenseQueryHookResult = ReturnType<
  typeof useGetItsmWorkspacesSuspenseQuery
>;
export type GetItsmWorkspacesQueryResult = Apollo.QueryResult<
  GetItsmWorkspacesQuery,
  GetItsmWorkspacesQueryVariables
>;
export const GetIdentitiesDocument = gql`
  query getIdentities(
    $serviceId: [ObjectID!]!
    $integrationId: [IntegrationId!]!
    $offset: Int!
    $limit: Int!
    $sort: GetIdentitiesSort!
    $q: String
  ) {
    identities(
      serviceId: $serviceId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
    ) {
      _id
      displayName
      servicesCount
      status
      serviceIdentities {
        service {
          _id
          displayName
          integration {
            integrationId
            description
          }
        }
      }
    }
  }
`;

/**
 * __useGetIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentitiesQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetIdentitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  > &
    (
      | { variables: GetIdentitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIdentitiesQuery, GetIdentitiesQueryVariables>(
    GetIdentitiesDocument,
    options
  );
}
export function useGetIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIdentitiesQuery, GetIdentitiesQueryVariables>(
    GetIdentitiesDocument,
    options
  );
}
export function useGetIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIdentitiesQuery,
    GetIdentitiesQueryVariables
  >(GetIdentitiesDocument, options);
}
export type GetIdentitiesQueryHookResult = ReturnType<
  typeof useGetIdentitiesQuery
>;
export type GetIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetIdentitiesLazyQuery
>;
export type GetIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetIdentitiesSuspenseQuery
>;
export type GetIdentitiesQueryResult = Apollo.QueryResult<
  GetIdentitiesQuery,
  GetIdentitiesQueryVariables
>;
export const GetAllIdentitiesDocument = gql`
  query getAllIdentities(
    $statuses: [IdentityStatus!]
    $serviceId: [ObjectID!]
    $integrationId: [IntegrationId!]
    $offset: Int
    $limit: Int
    $sort: GetIdentitiesSort
    $q: String
    $accountType: [IdentityAccountType]
    $owners: [ObjectID]
    $scope: IdentityScope
  ) {
    identities(
      statuses: $statuses
      serviceId: $serviceId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
      accountType: $accountType
      owners: $owners
      scope: $scope
    ) {
      _id
      displayName
      classification {
        accountType
        decidedAt
        scope
      }
      email
      status
      servicesCount
      owner {
        _id
        displayName
        email
        status
      }
      serviceIdentities {
        _id
        externalId
        displayName
        type
        service {
          _id
          displayName
          integration {
            integrationId
            description
          }
          inferred {
            integrationLookupId
            integrationId
          }
        }
        authResources {
          _id
          name
          externalId
          details {
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetAllIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIdentitiesQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      serviceId: // value for 'serviceId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *      accountType: // value for 'accountType'
 *      owners: // value for 'owners'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetAllIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllIdentitiesQuery, GetAllIdentitiesQueryVariables>(
    GetAllIdentitiesDocument,
    options
  );
}
export function useGetAllIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >(GetAllIdentitiesDocument, options);
}
export function useGetAllIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllIdentitiesQuery,
    GetAllIdentitiesQueryVariables
  >(GetAllIdentitiesDocument, options);
}
export type GetAllIdentitiesQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesQuery
>;
export type GetAllIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesLazyQuery
>;
export type GetAllIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllIdentitiesSuspenseQuery
>;
export type GetAllIdentitiesQueryResult = Apollo.QueryResult<
  GetAllIdentitiesQuery,
  GetAllIdentitiesQueryVariables
>;
export const GetIdentityDetailsDocument = gql`
  query getIdentityDetails($_id: ObjectID!, $offset: Int, $limit: Int) {
    identityDetails(_id: $_id) {
      _id
      displayName
      owner {
        _id
        displayName
        email
        status
      }
      servicesCount
      status
      serviceIdentities(offset: $offset, limit: $limit) {
        _id
        status
        service {
          _id
          displayName
          integration {
            integrationId
            description
            authorizedScopes
            activeAuthType
            status
          }
          status
          lastActivityIngest
        }
      }
      classification {
        decidedAt
        accountType
      }
    }
  }
`;

/**
 * __useGetIdentityDetailsQuery__
 *
 * To run a query within a React component, call `useGetIdentityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetIdentityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  > &
    (
      | { variables: GetIdentityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export function useGetIdentityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export function useGetIdentityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIdentityDetailsQuery,
    GetIdentityDetailsQueryVariables
  >(GetIdentityDetailsDocument, options);
}
export type GetIdentityDetailsQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsQuery
>;
export type GetIdentityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsLazyQuery
>;
export type GetIdentityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetIdentityDetailsSuspenseQuery
>;
export type GetIdentityDetailsQueryResult = Apollo.QueryResult<
  GetIdentityDetailsQuery,
  GetIdentityDetailsQueryVariables
>;
export const GetIdentityOwnersDocument = gql`
  query getIdentityOwners(
    $offset: Int
    $limit: Int
    $q: String
    $identityOwnersSort: GetIdentityOwnersSort
    $fetchUsers: Boolean
  ) {
    identityOwners(
      offset: $offset
      limit: $limit
      q: $q
      identityOwnersSort: $identityOwnersSort
      fetchUsers: $fetchUsers
    ) {
      _id
      org {
        _id
      }
      displayName
      email
      ownerType
    }
  }
`;

/**
 * __useGetIdentityOwnersQuery__
 *
 * To run a query within a React component, call `useGetIdentityOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdentityOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdentityOwnersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      identityOwnersSort: // value for 'identityOwnersSort'
 *      fetchUsers: // value for 'fetchUsers'
 *   },
 * });
 */
export function useGetIdentityOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >(GetIdentityOwnersDocument, options);
}
export function useGetIdentityOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >(GetIdentityOwnersDocument, options);
}
export function useGetIdentityOwnersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIdentityOwnersQuery,
    GetIdentityOwnersQueryVariables
  >(GetIdentityOwnersDocument, options);
}
export type GetIdentityOwnersQueryHookResult = ReturnType<
  typeof useGetIdentityOwnersQuery
>;
export type GetIdentityOwnersLazyQueryHookResult = ReturnType<
  typeof useGetIdentityOwnersLazyQuery
>;
export type GetIdentityOwnersSuspenseQueryHookResult = ReturnType<
  typeof useGetIdentityOwnersSuspenseQuery
>;
export type GetIdentityOwnersQueryResult = Apollo.QueryResult<
  GetIdentityOwnersQuery,
  GetIdentityOwnersQueryVariables
>;
export const IngestjobsDocument = gql`
  query Ingestjobs(
    $serviceId: [ObjectID!]
    $type: JobType
    $offset: Int!
    $limit: Int!
  ) {
    ingestjobs(
      serviceId: $serviceId
      type: $type
      offset: $offset
      limit: $limit
    ) {
      ...IngestJobFields
    }
  }
  ${IngestJobFieldsFragmentDoc}
`;

/**
 * __useIngestjobsQuery__
 *
 * To run a query within a React component, call `useIngestjobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngestjobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngestjobsQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      type: // value for 'type'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useIngestjobsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  > &
    (
      | { variables: IngestjobsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export function useIngestjobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export function useIngestjobsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IngestjobsQuery,
    IngestjobsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IngestjobsQuery, IngestjobsQueryVariables>(
    IngestjobsDocument,
    options
  );
}
export type IngestjobsQueryHookResult = ReturnType<typeof useIngestjobsQuery>;
export type IngestjobsLazyQueryHookResult = ReturnType<
  typeof useIngestjobsLazyQuery
>;
export type IngestjobsSuspenseQueryHookResult = ReturnType<
  typeof useIngestjobsSuspenseQuery
>;
export type IngestjobsQueryResult = Apollo.QueryResult<
  IngestjobsQuery,
  IngestjobsQueryVariables
>;
export const IngestjobDocument = gql`
  query Ingestjob($_id: ObjectID!) {
    getIngestJob(_id: $_id) {
      ...IngestJobFields
    }
  }
  ${IngestJobFieldsFragmentDoc}
`;

/**
 * __useIngestjobQuery__
 *
 * To run a query within a React component, call `useIngestjobQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngestjobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngestjobQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useIngestjobQuery(
  baseOptions: Apollo.QueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  > &
    ({ variables: IngestjobQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export function useIngestjobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export function useIngestjobSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IngestjobQuery,
    IngestjobQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<IngestjobQuery, IngestjobQueryVariables>(
    IngestjobDocument,
    options
  );
}
export type IngestjobQueryHookResult = ReturnType<typeof useIngestjobQuery>;
export type IngestjobLazyQueryHookResult = ReturnType<
  typeof useIngestjobLazyQuery
>;
export type IngestjobSuspenseQueryHookResult = ReturnType<
  typeof useIngestjobSuspenseQuery
>;
export type IngestjobQueryResult = Apollo.QueryResult<
  IngestjobQuery,
  IngestjobQueryVariables
>;
export const GetIntegrationCountsDocument = gql`
  query getIntegrationCounts($integrationId: IntegrationId) {
    getIntegrationCounts(integrationId: $integrationId) {
      integrationId
      count
    }
  }
`;

/**
 * __useGetIntegrationCountsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationCountsQuery({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useGetIntegrationCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export function useGetIntegrationCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export function useGetIntegrationCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetIntegrationCountsQuery,
    GetIntegrationCountsQueryVariables
  >(GetIntegrationCountsDocument, options);
}
export type GetIntegrationCountsQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsQuery
>;
export type GetIntegrationCountsLazyQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsLazyQuery
>;
export type GetIntegrationCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationCountsSuspenseQuery
>;
export type GetIntegrationCountsQueryResult = Apollo.QueryResult<
  GetIntegrationCountsQuery,
  GetIntegrationCountsQueryVariables
>;
export const LatestJobChainDocument = gql`
  query LatestJobChain($serviceId: ObjectID!, $type: JobChainType!) {
    latestJobChain(serviceId: $serviceId, type: $type) {
      _id
      startedAt
      completedAt
      status
      error
      missingScopes
    }
  }
`;

/**
 * __useLatestJobChainQuery__
 *
 * To run a query within a React component, call `useLatestJobChainQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestJobChainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestJobChainQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLatestJobChainQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  > &
    (
      | { variables: LatestJobChainQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestJobChainQuery, LatestJobChainQueryVariables>(
    LatestJobChainDocument,
    options
  );
}
export function useLatestJobChainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestJobChainQuery, LatestJobChainQueryVariables>(
    LatestJobChainDocument,
    options
  );
}
export function useLatestJobChainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LatestJobChainQuery,
    LatestJobChainQueryVariables
  >(LatestJobChainDocument, options);
}
export type LatestJobChainQueryHookResult = ReturnType<
  typeof useLatestJobChainQuery
>;
export type LatestJobChainLazyQueryHookResult = ReturnType<
  typeof useLatestJobChainLazyQuery
>;
export type LatestJobChainSuspenseQueryHookResult = ReturnType<
  typeof useLatestJobChainSuspenseQuery
>;
export type LatestJobChainQueryResult = Apollo.QueryResult<
  LatestJobChainQuery,
  LatestJobChainQueryVariables
>;
export const GetMeDocument = gql`
  query getMe {
    me {
      currentOrg {
        ...CurrentOrgFields
      }
      currentUser {
        ...CurrentUserFields
      }
    }
  }
  ${CurrentOrgFieldsFragmentDoc}
  ${CurrentUserFieldsFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export function useGetMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<
  typeof useGetMeSuspenseQuery
>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
export const GetMyOrgsDocument = gql`
  query getMyOrgs {
    myOrgs {
      ...CurrentOrgFields
    }
  }
  ${CurrentOrgFieldsFragmentDoc}
`;

/**
 * __useGetMyOrgsQuery__
 *
 * To run a query within a React component, call `useGetMyOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrgsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyOrgsQuery, GetMyOrgsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export function useGetMyOrgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOrgsQuery,
    GetMyOrgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export function useGetMyOrgsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyOrgsQuery,
    GetMyOrgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMyOrgsQuery, GetMyOrgsQueryVariables>(
    GetMyOrgsDocument,
    options
  );
}
export type GetMyOrgsQueryHookResult = ReturnType<typeof useGetMyOrgsQuery>;
export type GetMyOrgsLazyQueryHookResult = ReturnType<
  typeof useGetMyOrgsLazyQuery
>;
export type GetMyOrgsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyOrgsSuspenseQuery
>;
export type GetMyOrgsQueryResult = Apollo.QueryResult<
  GetMyOrgsQuery,
  GetMyOrgsQueryVariables
>;
export const GetPermissionSetsDocument = gql`
  query getPermissionSets(
    $serviceIdentityId: ObjectID!
    $authenticatorId: ObjectID!
    $limit: Int
    $offset: Int
  ) {
    permissionSets(
      serviceIdentityId: $serviceIdentityId
      authenticatorId: $authenticatorId
      limit: $limit
      offset: $offset
    ) {
      _id
      externalId
      name
      createdOn
      lastActivity
      details {
        type
        ...PermissionSetDetailField
        ...AuthenticatorDetailsField
      }
    }
  }
  ${PermissionSetDetailFieldFragmentDoc}
  ${AuthenticatorDetailsFieldFragmentDoc}
`;

/**
 * __useGetPermissionSetsQuery__
 *
 * To run a query within a React component, call `useGetPermissionSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionSetsQuery({
 *   variables: {
 *      serviceIdentityId: // value for 'serviceIdentityId'
 *      authenticatorId: // value for 'authenticatorId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPermissionSetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  > &
    (
      | { variables: GetPermissionSetsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  >(GetPermissionSetsDocument, options);
}
export function useGetPermissionSetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  >(GetPermissionSetsDocument, options);
}
export function useGetPermissionSetsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPermissionSetsQuery,
    GetPermissionSetsQueryVariables
  >(GetPermissionSetsDocument, options);
}
export type GetPermissionSetsQueryHookResult = ReturnType<
  typeof useGetPermissionSetsQuery
>;
export type GetPermissionSetsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionSetsLazyQuery
>;
export type GetPermissionSetsSuspenseQueryHookResult = ReturnType<
  typeof useGetPermissionSetsSuspenseQuery
>;
export type GetPermissionSetsQueryResult = Apollo.QueryResult<
  GetPermissionSetsQuery,
  GetPermissionSetsQueryVariables
>;
export const GetPermissionsDocument = gql`
  query getPermissions(
    $serviceIdentityId: ObjectID!
    $authenticatorId: ObjectID!
    $severities: [PermissionSeverity!]
    $showRegexPermissions: Boolean
    $permissionTypes: [PermissionType!]
    $q: String
    $offset: Int
    $limit: Int
  ) {
    permissions(
      serviceIdentityId: $serviceIdentityId
      authenticatorId: $authenticatorId
      severities: $severities
      showRegexPermissions: $showRegexPermissions
      permissionTypes: $permissionTypes
      q: $q
      offset: $offset
      limit: $limit
    ) {
      permission {
        action
        allowFilter
        denyFilter
        path
      }
      permissionDetails {
        name
        description
        severity
        type
        parentPermission
      }
    }
  }
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *      serviceIdentityId: // value for 'serviceIdentityId'
 *      authenticatorId: // value for 'authenticatorId'
 *      severities: // value for 'severities'
 *      showRegexPermissions: // value for 'showRegexPermissions'
 *      permissionTypes: // value for 'permissionTypes'
 *      q: // value for 'q'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  > &
    (
      | { variables: GetPermissionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    options
  );
}
export function useGetPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >(GetPermissionsDocument, options);
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetPermissionsSuspenseQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetServiceDetailsDocument = gql`
  query getServiceDetails($_id: ObjectID!, $offset: Int, $limit: Int) {
    serviceDetails(_id: $_id) {
      _id
      displayName
      status
      integration {
        integrationId
      }
      serviceIdentities(offset: $offset, limit: $limit) {
        _id
        identity {
          ... on Identity {
            _id
            displayName
            classification {
              decidedAt
              accountType
            }
            email
            owner {
              _id
              displayName
              email
              status
            }
          }
          ... on Error {
            message
          }
        }
        status
      }
    }
  }
`;

/**
 * __useGetServiceDetailsQuery__
 *
 * To run a query within a React component, call `useGetServiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceDetailsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetServiceDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  > &
    (
      | { variables: GetServiceDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export function useGetServiceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export function useGetServiceDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceDetailsQuery,
    GetServiceDetailsQueryVariables
  >(GetServiceDetailsDocument, options);
}
export type GetServiceDetailsQueryHookResult = ReturnType<
  typeof useGetServiceDetailsQuery
>;
export type GetServiceDetailsLazyQueryHookResult = ReturnType<
  typeof useGetServiceDetailsLazyQuery
>;
export type GetServiceDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceDetailsSuspenseQuery
>;
export type GetServiceDetailsQueryResult = Apollo.QueryResult<
  GetServiceDetailsQuery,
  GetServiceDetailsQueryVariables
>;
export const GetServiceIdentityCountsDocument = gql`
  query getServiceIdentityCounts($_id: ObjectID!) {
    serviceDetails(_id: $_id) {
      serviceIdentityCount
    }
  }
`;

/**
 * __useGetServiceIdentityCountsQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityCountsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetServiceIdentityCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  > &
    (
      | { variables: GetServiceIdentityCountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export function useGetServiceIdentityCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export function useGetServiceIdentityCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityCountsQuery,
    GetServiceIdentityCountsQueryVariables
  >(GetServiceIdentityCountsDocument, options);
}
export type GetServiceIdentityCountsQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsQuery
>;
export type GetServiceIdentityCountsLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsLazyQuery
>;
export type GetServiceIdentityCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityCountsSuspenseQuery
>;
export type GetServiceIdentityCountsQueryResult = Apollo.QueryResult<
  GetServiceIdentityCountsQuery,
  GetServiceIdentityCountsQueryVariables
>;
export const GetAllServiceIdentitiesDocument = gql`
  query getAllServiceIdentities(
    $serviceIds: [ObjectID!]
    $integrationId: [IntegrationId!]
    $authResourceTypes: [AuthResourceType!]
    $owners: [ObjectID!]
    $granularTypes: [GranularType!]
    $offset: Int
    $limit: Int
    $sort: GetServiceIdentitiesSort
    $q: String
    $filterOnValidPermissions: Boolean
  ) {
    serviceIdentities(
      serviceIds: $serviceIds
      integrationId: $integrationId
      authResourceTypes: $authResourceTypes
      owners: $owners
      granularTypes: $granularTypes
      offset: $offset
      limit: $limit
      sort: $sort
      q: $q
    ) {
      _id
      externalId
      type
      displayName
      lastActivity
      service {
        _id
        displayName
        instanceName
        integration {
          integrationId
          description
        }
        inferred {
          integrationId
          integrationLookupId
        }
        auxiliary {
          integrationId
        }
        type
      }
      identity {
        ...IdentityFieldForList
      }
      principal {
        principal
      }
      authResources(filterOnValidPermissions: $filterOnValidPermissions) {
        _id
        name
        externalId
        details {
          type
        }
      }
      openAlerts {
        type
        severity
      }
      granularType
      totalCount
    }
  }
  ${IdentityFieldForListFragmentDoc}
`;

/**
 * __useGetAllServiceIdentitiesQuery__
 *
 * To run a query within a React component, call `useGetAllServiceIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceIdentitiesQuery({
 *   variables: {
 *      serviceIds: // value for 'serviceIds'
 *      integrationId: // value for 'integrationId'
 *      authResourceTypes: // value for 'authResourceTypes'
 *      owners: // value for 'owners'
 *      granularTypes: // value for 'granularTypes'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      q: // value for 'q'
 *      filterOnValidPermissions: // value for 'filterOnValidPermissions'
 *   },
 * });
 */
export function useGetAllServiceIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export function useGetAllServiceIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export function useGetAllServiceIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllServiceIdentitiesQuery,
    GetAllServiceIdentitiesQueryVariables
  >(GetAllServiceIdentitiesDocument, options);
}
export type GetAllServiceIdentitiesQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesQuery
>;
export type GetAllServiceIdentitiesLazyQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesLazyQuery
>;
export type GetAllServiceIdentitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllServiceIdentitiesSuspenseQuery
>;
export type GetAllServiceIdentitiesQueryResult = Apollo.QueryResult<
  GetAllServiceIdentitiesQuery,
  GetAllServiceIdentitiesQueryVariables
>;
export const GetServiceIdentityAuthResourcesDocument = gql`
  query getServiceIdentityAuthResources(
    $id: ObjectID!
    $start: Int
    $limit: Int
    $types: [AuthResourceType!]
    $filterOnValidPermissions: Boolean
    $sort: GetAuthResourcesSort
  ) {
    serviceIdentity(id: $id) {
      _id
      authResources(
        start: $start
        limit: $limit
        types: $types
        sort: $sort
        filterOnValidPermissions: $filterOnValidPermissions
      ) {
        _id
        externalId
        name
        createdOn
        lastActivity
        details {
          type
          ...PermissionSetDetailField
        }
      }
    }
  }
  ${PermissionSetDetailFieldFragmentDoc}
`;

/**
 * __useGetServiceIdentityAuthResourcesQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityAuthResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityAuthResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityAuthResourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *      types: // value for 'types'
 *      filterOnValidPermissions: // value for 'filterOnValidPermissions'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetServiceIdentityAuthResourcesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  > &
    (
      | {
          variables: GetServiceIdentityAuthResourcesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export function useGetServiceIdentityAuthResourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export function useGetServiceIdentityAuthResourcesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityAuthResourcesQuery,
    GetServiceIdentityAuthResourcesQueryVariables
  >(GetServiceIdentityAuthResourcesDocument, options);
}
export type GetServiceIdentityAuthResourcesQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesQuery
>;
export type GetServiceIdentityAuthResourcesLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesLazyQuery
>;
export type GetServiceIdentityAuthResourcesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityAuthResourcesSuspenseQuery
>;
export type GetServiceIdentityAuthResourcesQueryResult = Apollo.QueryResult<
  GetServiceIdentityAuthResourcesQuery,
  GetServiceIdentityAuthResourcesQueryVariables
>;
export const GetServiceIdentityDetailsDocument = gql`
  query getServiceIdentityDetails($id: ObjectID!) {
    serviceIdentity(id: $id) {
      _id
      externalId
      displayName
      username
      lastActivity
      owner {
        _id
        displayName
        email
      }
      service {
        _id
        displayName
        instanceName
        type
        integration {
          integrationId
          description
        }
        inferred {
          integrationId
          integrationLookupId
        }
        auxiliary {
          integrationId
        }
      }
      identity {
        ...IdentityField
      }
      metadata {
        createdAt
        lastActiveAt
        lastProgrammaticActivity
        lastInteractiveActivity
      }
      granularType
      integrationId
      attributes
    }
  }
  ${IdentityFieldFragmentDoc}
`;

/**
 * __useGetServiceIdentityDetailsQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetServiceIdentityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  > &
    (
      | { variables: GetServiceIdentityDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export function useGetServiceIdentityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export function useGetServiceIdentityDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityDetailsQuery,
    GetServiceIdentityDetailsQueryVariables
  >(GetServiceIdentityDetailsDocument, options);
}
export type GetServiceIdentityDetailsQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsQuery
>;
export type GetServiceIdentityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsLazyQuery
>;
export type GetServiceIdentityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityDetailsSuspenseQuery
>;
export type GetServiceIdentityDetailsQueryResult = Apollo.QueryResult<
  GetServiceIdentityDetailsQuery,
  GetServiceIdentityDetailsQueryVariables
>;
export const GetServiceIdentityWidgetCountsDocument = gql`
  query getServiceIdentityWidgetCounts(
    $serviceIds: [ObjectID!]
    $integrationId: [IntegrationId!]
    $authResourceTypes: [AuthResourceType!]
    $owners: [ObjectID!]
    $granularTypes: [GranularType!]
    $q: String
  ) {
    serviceIdentityWidgetCounts(
      serviceIds: $serviceIds
      integrationId: $integrationId
      authResourceTypes: $authResourceTypes
      owners: $owners
      granularTypes: $granularTypes
      q: $q
    ) {
      accountsByType {
        _id
        count
      }
      accountsByService {
        _id
        count
      }
      authenticatorsByType {
        _id
        count
      }
    }
  }
`;

/**
 * __useGetServiceIdentityWidgetCountsQuery__
 *
 * To run a query within a React component, call `useGetServiceIdentityWidgetCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceIdentityWidgetCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceIdentityWidgetCountsQuery({
 *   variables: {
 *      serviceIds: // value for 'serviceIds'
 *      integrationId: // value for 'integrationId'
 *      authResourceTypes: // value for 'authResourceTypes'
 *      owners: // value for 'owners'
 *      granularTypes: // value for 'granularTypes'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetServiceIdentityWidgetCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >(GetServiceIdentityWidgetCountsDocument, options);
}
export function useGetServiceIdentityWidgetCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >(GetServiceIdentityWidgetCountsDocument, options);
}
export function useGetServiceIdentityWidgetCountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceIdentityWidgetCountsQuery,
    GetServiceIdentityWidgetCountsQueryVariables
  >(GetServiceIdentityWidgetCountsDocument, options);
}
export type GetServiceIdentityWidgetCountsQueryHookResult = ReturnType<
  typeof useGetServiceIdentityWidgetCountsQuery
>;
export type GetServiceIdentityWidgetCountsLazyQueryHookResult = ReturnType<
  typeof useGetServiceIdentityWidgetCountsLazyQuery
>;
export type GetServiceIdentityWidgetCountsSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceIdentityWidgetCountsSuspenseQuery
>;
export type GetServiceIdentityWidgetCountsQueryResult = Apollo.QueryResult<
  GetServiceIdentityWidgetCountsQuery,
  GetServiceIdentityWidgetCountsQueryVariables
>;
export const GetServiceInstancesDocument = gql`
  query getServiceInstances {
    serviceInstances
  }
`;

/**
 * __useGetServiceInstancesQuery__
 *
 * To run a query within a React component, call `useGetServiceInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceInstancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >(GetServiceInstancesDocument, options);
}
export function useGetServiceInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >(GetServiceInstancesDocument, options);
}
export function useGetServiceInstancesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceInstancesQuery,
    GetServiceInstancesQueryVariables
  >(GetServiceInstancesDocument, options);
}
export type GetServiceInstancesQueryHookResult = ReturnType<
  typeof useGetServiceInstancesQuery
>;
export type GetServiceInstancesLazyQueryHookResult = ReturnType<
  typeof useGetServiceInstancesLazyQuery
>;
export type GetServiceInstancesSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceInstancesSuspenseQuery
>;
export type GetServiceInstancesQueryResult = Apollo.QueryResult<
  GetServiceInstancesQuery,
  GetServiceInstancesQueryVariables
>;
export const GetServicesDocument = gql`
  query getServices(
    $identityId: [ObjectID!]
    $integrationId: [IntegrationId!]
    $offset: Int
    $limit: Int
    $q: String
    $isIntegration: Boolean
  ) {
    services(
      identityId: $identityId
      integrationId: $integrationId
      offset: $offset
      limit: $limit
      q: $q
      isIntegration: $isIntegration
    ) {
      _id
      displayName
      instanceName
      status
      integration {
        integrationId
        description
        activeAuthType
        authFields
        projectKey
      }
      serviceIdentityCount
      isDefaultITSMService
    }
  }
`;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *      integrationId: // value for 'integrationId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      isIntegration: // value for 'isIntegration'
 *   },
 * });
 */
export function useGetServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export function useGetServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export function useGetServicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServicesQuery,
    GetServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetServicesQuery, GetServicesQueryVariables>(
    GetServicesDocument,
    options
  );
}
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<
  typeof useGetServicesLazyQuery
>;
export type GetServicesSuspenseQueryHookResult = ReturnType<
  typeof useGetServicesSuspenseQuery
>;
export type GetServicesQueryResult = Apollo.QueryResult<
  GetServicesQuery,
  GetServicesQueryVariables
>;
export const GetUnassignedIdentitiesCountDocument = gql`
  query getUnassignedIdentitiesCount($unassignedField: UnassignedFieldEnum!) {
    unassignedIdentitiesCount(unassignedField: $unassignedField)
  }
`;

/**
 * __useGetUnassignedIdentitiesCountQuery__
 *
 * To run a query within a React component, call `useGetUnassignedIdentitiesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnassignedIdentitiesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnassignedIdentitiesCountQuery({
 *   variables: {
 *      unassignedField: // value for 'unassignedField'
 *   },
 * });
 */
export function useGetUnassignedIdentitiesCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  > &
    (
      | {
          variables: GetUnassignedIdentitiesCountQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  >(GetUnassignedIdentitiesCountDocument, options);
}
export function useGetUnassignedIdentitiesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  >(GetUnassignedIdentitiesCountDocument, options);
}
export function useGetUnassignedIdentitiesCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUnassignedIdentitiesCountQuery,
    GetUnassignedIdentitiesCountQueryVariables
  >(GetUnassignedIdentitiesCountDocument, options);
}
export type GetUnassignedIdentitiesCountQueryHookResult = ReturnType<
  typeof useGetUnassignedIdentitiesCountQuery
>;
export type GetUnassignedIdentitiesCountLazyQueryHookResult = ReturnType<
  typeof useGetUnassignedIdentitiesCountLazyQuery
>;
export type GetUnassignedIdentitiesCountSuspenseQueryHookResult = ReturnType<
  typeof useGetUnassignedIdentitiesCountSuspenseQuery
>;
export type GetUnassignedIdentitiesCountQueryResult = Apollo.QueryResult<
  GetUnassignedIdentitiesCountQuery,
  GetUnassignedIdentitiesCountQueryVariables
>;
export const GetUsersDocument = gql`
  query getUsers(
    $offset: Int
    $limit: Int
    $q: String
    $sort: GetUsersSort
    $status: [UserStatus]
  ) {
    users(offset: $offset, limit: $limit, q: $q, sort: $sort, status: $status) {
      _id
      org {
        _id
      }
      displayName
      email
      status
      role
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export function useGetUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersSuspenseQueryHookResult = ReturnType<
  typeof useGetUsersSuspenseQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
